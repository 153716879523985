import { Spacer } from '@rmstransactions/components';
import React, { useState } from 'react';
import { HelpText } from './NeedHelpButton.style';

const helpText = {
  1: `‘Since last obtaining your licence’ means since the last time you got
a new licence card. Only answer 'Yes' if the prohibition or refusal
happened after your most recent licence renewal or replacement.`,
  2: ` ‘Since last obtaining your licence’ means since the last time you got
a new licence card. Only answer ‘Yes’ if the disqualification,
cancellation, suspension, or pending charge happened after your most
recent licence renewal, replacement or upgrade.`,
};

const NeedHelpButton: React.FC<{ index: number }> = ({ index }) => {
  const [toggleHelpText, setToggleHelpText] = useState<boolean>(false);
  return (
    <Spacer mt={1} mb={1}>
      <a
        href=''
        onClick={(e) => {
          e.preventDefault();
          setToggleHelpText((prev) => !prev);
        }}
        data-testid={'needHelpButton' + index}
      >
        {toggleHelpText ? 'Close help' : 'Need help with this question?'}
      </a>

      {toggleHelpText && <HelpText>{helpText[index]}</HelpText>}
    </Spacer>
  );
};

export { NeedHelpButton };
