import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConcessionCardTypeDefinition } from '../../pages/YourLicence/YourLicenceHelper';

export interface ConcessionCardState {
  concessionCardNumber: string;
  concessionType: ConcessionCardTypeDefinition;
}

export const initialState: ConcessionCardState = {
  concessionCardNumber: null,
  concessionType: null,
};

export const concessionCardSlice = createSlice({
  name: 'concessionCard',
  initialState,
  reducers: {
    setConcessionCardNumber: (state, action: PayloadAction<string>) => {
      state.concessionCardNumber = action.payload;
    },
    setConcessionType: (
      state,
      action: PayloadAction<ConcessionCardTypeDefinition>
    ) => {
      state.concessionType = action.payload;
    },
    reset: () => initialState,
  },
});

export const concessionCardSliceActions = concessionCardSlice.actions;
export const concessionCardReducer = concessionCardSlice.reducer;
