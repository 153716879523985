import React, { useEffect, useState } from 'react';
import transactionCleanupEvents from 'utils/session/transactionCleanupEvents/transactionCleanupEvents';
import {
  goToDrupalFirstPage,
  logoutAndResetUserSession,
  minutesToMilliseconds,
} from 'utils/helpers/helpers';
import { Modal } from '@snsw/react-component-library';

const TimeoutHandler: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleQuitButton = async (): Promise<void> => {
    try {
      await transactionCleanupEvents();
      await logoutAndResetUserSession();
      setIsOpen(false);
      localStorage.setItem('modalOpen', 'false');
    } catch (error) {
      // Handle error if needed
    } finally {
      goToDrupalFirstPage();
    }
  };

  useEffect(() => {
    const isModalOpen = localStorage.getItem('modalOpen');
    setIsOpen(isModalOpen === 'true');

    if (!isModalOpen) {
      const timeoutId = setTimeout(() => {
        setIsOpen(true);
        localStorage.setItem('modalOpen', 'true'); // Update local storage
      }, minutesToMilliseconds(15));

      return () => clearTimeout(timeoutId);
    }
  }, []);

  return (
    <>
      {isOpen && (
        <Modal
          title='Your session has timed out'
          description='There has been no activity for the last 15 minutes or more. For your security, your session has ended.'
          buttons={[{ text: 'Quit', onClick: handleQuitButton }]}
        />
      )}
    </>
  );
};

export default TimeoutHandler;
