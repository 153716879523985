import React from 'react';
import { Heading } from '@snsw/react-component-library';
import { FormRadioGroupRms } from '@rmstransactions/components';
import { NeedHelpButton } from 'components/NeedHelpButton/NeedHelpButton';
import { useStoreDispatch, useStoreSelector } from 'redux/store';
import { reviewDeclarationSliceActions } from 'redux/slice/reviewDeclaration';

interface QuestionRadioGroupProps {
  id: number;
}

const QuestionRadioGroup: React.FC<QuestionRadioGroupProps> = ({ id }) => {
  const dispatch = useStoreDispatch();

  const handleQuestion1 = (selectedAnswer1) => {
    dispatch(reviewDeclarationSliceActions.setErrorInAnswer1(false));
    dispatch(reviewDeclarationSliceActions.setAnswer1(selectedAnswer1));
  };

  const handleQuestio2 = (selectedAnswer2) => {
    dispatch(reviewDeclarationSliceActions.setErrorInAnswer2(false));
    dispatch(reviewDeclarationSliceActions.setAnswer2(selectedAnswer2));
  };

  const {
    selectedAnswer1,
    selectedAnswer2,
    question1,
    question2,
    isErrorInAnswer1,
    isErrorInAnswer2,
  } = useStoreSelector((state) => ({
    selectedAnswer1: state.reviewDeclaration.answer1,
    selectedAnswer2: state.reviewDeclaration.answer2,
    question1: state.declarationForm.question1,
    question2: state.declarationForm.question2,
    isErrorInAnswer1: state.reviewDeclaration.errorInAnswer1,
    isErrorInAnswer2: state.reviewDeclaration.errorInAnswer2,
  }));

  return (
    <>
      <Heading level={5}>
        {id}. {id === 1 ? question1 : question2}
      </Heading>
      <NeedHelpButton index={id} />
      <FormRadioGroupRms
        id={`your-declaration-question-${id}`}
        options={[
          {
            label: 'Yes',
            value: 'Yes',
          },
          {
            label: 'No',
            value: 'No',
          },
        ]}
        vertical
        value={id === 1 ? selectedAnswer1 : selectedAnswer2}
        onChange={id === 1 ? handleQuestion1 : handleQuestio2}
        hasErrorInline={id === 1 ? isErrorInAnswer1 : isErrorInAnswer2}
        errorMessage={`Please select 'Yes' or 'No' to continue`}
        mt='0.5rem'
      />
    </>
  );
};

export default QuestionRadioGroup;
