import styled from 'styled-components';

export const CustomerDetailsWrapper = styled.div`
  margin-bottom: 1rem;
  line-height: 1.3;
`;

export const VehicleInfoContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
  gap: 1.5rem;
`;

export const CustomerLicenceExpiry = styled.div`
  margin-top: 0.25rem;
`;
