import React, { useEffect } from 'react';
import { useStoreSelector } from 'redux/store';
import ErrorLayout from './components/ErrorLayout';
import { LicenceStatusDetails } from 'utils/helpers/ErrorHandlers/apiErrorCheck';
import { ErrorCodes } from 'utils/helpers/ErrorHandlers/helpers/helpers';
import {
  dualClassBothExpiredDescription,
  dualClassDescription,
  dualClassTitle,
  dualClassTitleBothExpired,
} from 'utils/helpers/ErrorHandlers/helpers/errorContentDetails';
import { useParams } from 'react-router-dom';
import { RoutePathParams } from '../../utils/helpers/helpers';
import transactionCleanupEvents from 'utils/session/transactionCleanupEvents/transactionCleanupEvents';

const errorCodesToReplace = [
  ErrorCodes.LIC_STATUS_DISQUALIFIED,
  ErrorCodes.LIC_STATUS_CANCELLED,
  ErrorCodes.LIC_STATUS_SUSPENDED,
  ErrorCodes.LIC_EXPIRED,
];

const checkIfBothExpired = (arrIdentifier) => {
  return (
    arrIdentifier?.length >= 2 &&
    arrIdentifier[0] === ErrorCodes.LIC_EXPIRED &&
    arrIdentifier[1] === ErrorCodes.LIC_EXPIRED
  );
};

const checkIfBothInvalid = (arrIdentifier) => {
  return (
    arrIdentifier?.length >= 2 &&
    errorCodesToReplace?.includes(arrIdentifier[0]) &&
    errorCodesToReplace?.includes(arrIdentifier[1])
  );
};

const getPageHeading = (arrIdentifier) => {
  return (
    arrIdentifier &&
    arrIdentifier[0] &&
    LicenceStatusDetails[arrIdentifier[0]] &&
    LicenceStatusDetails[arrIdentifier[0]].pageHeading
  );
};

const ErrorPage: React.FC = () => {
  const {
    eligibilityIdentifier,
    validateIdentifiers,
    showInfoArray,
    interimLicenceErrors,
    showInfoArrayValidate,
  } = useStoreSelector((state) => ({
    eligibilityIdentifier: state.eligibility.eligibilityIdentifier,
    validateIdentifiers: state.validate.validationErrorCodes,
    showInfoArray: state.eligibility.showInfoArray,
    interimLicenceErrors: state.interimLicence.errorCodes,
    showInfoArrayValidate: state.validate.showInfoArray,
  }));
  const { errorCode } = useParams<RoutePathParams>();

  const getErrorDetails = (): {
    pageHeading: string;
    errorTitle: string;
    errorDescription: JSX.Element | string;
  } => {
    const identifier: string[] = [];
    if (eligibilityIdentifier) identifier.push(...eligibilityIdentifier);
    if (validateIdentifiers) identifier.push(...validateIdentifiers);
    if (errorCode) identifier.push(errorCode);

    if (checkIfBothExpired(identifier)) {
      return {
        pageHeading: getPageHeading(identifier),
        errorTitle: dualClassTitleBothExpired,
        errorDescription: dualClassBothExpiredDescription,
      };
    } else if (checkIfBothInvalid(identifier)) {
      return {
        pageHeading: getPageHeading(identifier),
        errorTitle: dualClassTitle,
        errorDescription: dualClassDescription,
      };
    } else if (interimLicenceErrors?.length > 0) {
      return {
        pageHeading: getPageHeading(interimLicenceErrors),
        errorTitle:
          interimLicenceErrors &&
          interimLicenceErrors[0] &&
          LicenceStatusDetails[interimLicenceErrors[0]] &&
          LicenceStatusDetails[interimLicenceErrors[0]].title,
        errorDescription:
          interimLicenceErrors &&
          interimLicenceErrors[0] &&
          LicenceStatusDetails[interimLicenceErrors[0]] &&
          LicenceStatusDetails[interimLicenceErrors[0]].description,
      };
    } else {
      return {
        pageHeading: getPageHeading(identifier),
        errorTitle:
          identifier &&
          identifier[0] &&
          LicenceStatusDetails[identifier[0]] &&
          LicenceStatusDetails[identifier[0]].title,
        errorDescription:
          identifier &&
          identifier[0] &&
          LicenceStatusDetails[identifier[0]] &&
          LicenceStatusDetails[identifier[0]].description,
      };
    }
  };

  useEffect(() => {
    transactionCleanupEvents();
  }, []);

  return (
    <>
      <ErrorLayout
        pageHeading={getErrorDetails().pageHeading}
        errorHeading={getErrorDetails().errorTitle}
        showInfoArray={showInfoArray}
        showInfoArrayValidate={showInfoArrayValidate}
      >
        {getErrorDetails().errorDescription}
      </ErrorLayout>
    </>
  );
};

export default ErrorPage;
