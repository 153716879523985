import React from 'react';
import * as Styled from './EmailNotificationSuccess.styled';

const EmailNotificationSuccess: React.FC<EmailNotificationSuccessProps> = ({
  visible,
  emailAddress,
}) => (
  <>
    {visible && (
      <Styled.OverflowTextInPageAlert
        variant='success'
        title={`Your receipt has been sent to ${emailAddress}`}
      />
    )}
  </>
);

export default EmailNotificationSuccess;

interface EmailNotificationSuccessProps {
  visible: boolean;
  emailAddress: string;
}
