import styled, { keyframes } from 'styled-components';
import { tokens } from '@snsw/react-component-library';
import { Breakpoint } from '@rmstransactions/components/Styled';

export const CardValidationInfo = styled.p`
  font-size: 0.875rem;
  color: ${tokens.colors.grey.shuttle};
  margin-top: 0rem;
  margin-bottom: 0;
  line-height: 1.2rem;
`;
export const CardLostInfo = styled.p`
  font-size: 0.875rem;
  color: ${tokens.colors.grey.shuttle};
  font-weight: bold;
  margin: 0;
  line-height: 1.2rem;
`;

export const MoreInfoModalWrapper = styled.span`
  margin-top: 1rem;
  button {
    font-weight: 300;
  }
  button:focus {
    outline: 2px solid transparent;
  }
  button:hover:not(:disabled) {
    background-color: unset;
    color: ${tokens.colors.brand.snswSecondaryBlue};
  }
  div > div > button:hover:not(:disabled) {
    background-color: ${tokens.colors.brand.snswSecondaryBlue};
    color: #fff;
  }
  div > div > div > div > span {
    line-height: 2.5rem;
  }
  @media only screen and (max-width: 768px) {
    div[role='dialog'] {
      left: 10%;
      width: 90%;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  .button:first-child {
    @media only screen and (max-width: 577px) {
      width: 100%;
    }
  }

  .button:last-child {
    margin-left: 0;
    margin-top: 1rem;
    ${Breakpoint('sm')`
    margin-left: 1.5rem;
    margin-top: 0;

    `}
    @media only screen and (max-width: 577px) {
      width: 100%;
      margin-left: 0;
      margin-top: 1rem;
    }
  }
`;

export const RadioButtonsContainer = styled.div`
  max-width: 35.625rem;
`;

export const ProtectIdentityDescription = styled.span`
  margin-right: 0.5rem;
`;

export const TextLinkStyle = styled.a`
  color: ${tokens.colors.brand.snswSecondaryBlue} !important;
`;

const breatheAnimation = keyframes`
0% {
  opacity: 0;
  transform: translateY(-20px);
}
100% {
  opacity: 1;
  transform: translateY(0);
}`;

export const AnimationStyle = styled.div`
  animation-name: ${breatheAnimation};
  animation-duration: 400ms;
  animation-iteration-count: initial;
`;
