import React from 'react';
import { ComponentLoader } from '@snsw/react-component-library';

interface LoadingOverlayProps {
  visible: boolean;
  label?: string;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  visible,
  label = loadingOverlayLabelText,
}) => (visible ? <ComponentLoader label={label} fullPage /> : <></>);

export default LoadingOverlay;
const loadingOverlayLabelText = 'One moment, please...';
