export default interface IPaymentRequest {
  amount?: number;
  kioskId: string;
}

export interface IEmailRequest {
  emailAddress: string;
  uuid?: string;
}

export type ResponseType = 'SUCCESS' | 'ERROR';

export const ResponseTypeValue: Record<ResponseType, string> = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};
