import styled from 'styled-components';
import { tokens } from '@snsw/react-component-library';

export const LinkStyle = styled.a`
  text-decoration: none !important;
  border-bottom: 0.031rem solid ${tokens.colors.grey.shuttle};
`;

export const ListStyle = styled.li`
  mergin-bottom: 0.031rem;
`;
