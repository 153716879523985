import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { RoutePathParams } from '../../utils/helpers/helpers';
import sessionStorageManager from 'utils/session/sessionStorageManager';
import { sessionStorageKeyForReceiptUUID } from 'constants/constants';

const AlmostDoneRedirect: React.FC = () => {
  const history = useHistory();
  const { receiptUUID } = useParams<RoutePathParams>();

  if (receiptUUID) {
    sessionStorageManager.setValue(
      sessionStorageKeyForReceiptUUID,
      receiptUUID
    );
  }

  useEffect(() => {
    history.push({
      pathname: '/almost-done',
      state: {
        receiptUUID: receiptUUID,
      },
    });
  }, [receiptUUID]);

  return <></>;
};

export default AlmostDoneRedirect;
