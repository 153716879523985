import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  answer1: string | null;
  answer2: string | null;
  errorInAnswer1: boolean | null;
  errorInAnswer2: boolean | null;
}

export const initialState: UserState = {
  answer1: null,
  answer2: null,
  errorInAnswer1: null,
  errorInAnswer2: null,
};

export const reviewDeclarationSlice = createSlice({
  name: 'declaration',
  initialState,
  reducers: {
    setAnswer1: (state, action: PayloadAction<string>) => {
      state.answer1 = action.payload;
    },
    setAnswer2: (state, action: PayloadAction<string>) => {
      state.answer2 = action.payload;
    },
    setErrorInAnswer1: (state, action: PayloadAction<boolean>) => {
      state.errorInAnswer1 = action.payload;
    },
    setErrorInAnswer2: (state, action: PayloadAction<boolean>) => {
      state.errorInAnswer2 = action.payload;
    },
    reset: () => initialState,
  },
});

export const reviewDeclarationSliceActions = reviewDeclarationSlice.actions;
export const reviewDeclarationReducer = reviewDeclarationSlice.reducer;
