import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStoreSelector } from 'redux/store';
import pushAnalyticsNewPage from 'utils/session/pushAnalyticsNewPage/pushAnalyticsNewPage';

const GoogleAnalyticsMonitor: React.FC = () => {
  const { licenceCategoryConfirmation, licenceCategoryLicence } =
    useStoreSelector((state) => ({
      licenceCategoryConfirmation: state?.confirmation?.licenceCategory,
      licenceCategoryLicence: state.licence.licenceCategory,
    }));
  const history = useHistory();

  const licenceTypeVehicleOneConfirmation =
    licenceCategoryConfirmation && licenceCategoryConfirmation[0]?.licenceType;
  const licenceTypeVehicleTwoConfirmation =
    licenceCategoryConfirmation && licenceCategoryConfirmation[1]?.licenceType;

  const licenceTypeVehicleOneLicence =
    licenceCategoryLicence && licenceCategoryLicence[0]?.licenceType;
  const licenceTypeVehicleTwoLicence =
    licenceCategoryLicence && licenceCategoryLicence[1]?.licenceType;

  useEffect(() => {
    pushAnalyticsNewPage(
      licenceTypeVehicleOneLicence || licenceTypeVehicleOneConfirmation,
      licenceTypeVehicleTwoLicence || licenceTypeVehicleTwoConfirmation
    );
    history.listen(() => {
      pushAnalyticsNewPage(
        licenceTypeVehicleOneLicence || licenceTypeVehicleOneConfirmation,
        licenceTypeVehicleTwoLicence || licenceTypeVehicleTwoConfirmation
      );
    });
  }, [
    history,
    licenceTypeVehicleOneLicence,
    licenceTypeVehicleTwoLicence,
    licenceTypeVehicleOneConfirmation,
    licenceTypeVehicleTwoConfirmation,
  ]);

  return null;
};

export default GoogleAnalyticsMonitor;
