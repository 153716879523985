export const TRANSACTION_STATE = 'RR';
export const TECHNICAL_ERROR_PAGE_HEADER = "We're sorry about this...";
export const TECHNICAL_ERROR_PAGE_MESSAGE_HEADLINE =
  'Our system is temporarily unavailable';
export const TECHNICAL_ERROR_PAGE_MESSAGE =
  'You can try again later, call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>. We apologise for any inconvenience.';
export const BILLING = 'BILLING';
export const PLATE = 'PLATE';
export const INITIAL_QUOTE = 'InitialQuote';
export const FULL_VALIDATION = 'FullValidation';
export const SCHEME_FULL = 'Full';
export const SCHEME_CONDITIONAL = 'Conditional';
export const PENSIONER_CONCESSION_URL =
  'https://www.nsw.gov.au/topics/vehicle-registration/renew/pensioner-concessions-and-automatic-renewal#toc-concessions-and-benefits';
export const DRUPAL_RENEW_REGISTRATION_URL =
  'https://www.service.nsw.gov.au/transaction/renew-vehicle-registration';
export const HELP_US_IMPROVE_URL =
  'https://forms.office.com/Pages/ResponsePage.aspx?id=aHr5Hqvo7UShbbV5_i182PtlyLBVxWtPl_8iMw2zr_NUNTIxQjMzR0s1TDZORFVWVU4yMlFWTjNSVi4u';
export const SNSW_HOME_PAGE_URL = 'https://www.service.nsw.gov.au/';
export const RENEW_REGISTRATION_TRANSACTION_URL =
  process.env.REACT_APP_API_HOST + '/find-vehicle';
export const usageTypeCodeList = [
  'PRIV',
  'PNSR',
  'PNIC',
  'BKSV',
  'BUSG',
  'SBTX',
  'TAXI',
];
export const bodyShapeCodeList = [
  'FCV',
  'CYC',
  'PVW',
  'PVS',
  'SED',
  'TTF',
  'UTE',
  'COU',
  'MH',
  'CYS',
  'PVN',
  'PVF',
  'SBS',
  'WAG',
  'TT',
  'VAN',
  'TWC',
];
export const DOC_ID_CHANGE_USAGE_APPLY_BTN = 'changeUsageApplyBtn';
export const TUTD_APPLICATION_NAME = 'registration-renewal';
export const DOC_ID_T_AND_C_S = 'termsAndConditions';
export const DOC_ID_T_AND_C_S_VEOS = 'termsAndConditionsVEOS';
export const DOC_ID_SELF_DECLARATION = 'selfDeclarationStatement';
export const DOC_ID_PPG_DECLARATION = 'ppgDeclaration';
export const ERROR_MESSAGE_EMAIL =
  'Invalid email address. Please use the format example@email.com';
export const ERROR_MESSAGE_CONFIRM_CHANGE_USAGE =
  'Apply your new vehicle usage or cancel changes to continue.';
export const ERROR_MESSAGE_DECLARATION =
  'Please accept the declaration to continue.';
export const ERROR_MESSAGE_TERMS_AND_CONDTIONS_VEOS =
  'Please accept the Carbon offset contribution Terms and Conditions to continue.';
export const ERROR_MESSAGE_TERMS_AND_CONDTIONS =
  'Please accept the Registration renewal Terms and Conditions to continue.';
export const ERROR_MESSAGE_TECHNICAL_ERROR = [
  {
    severity: 'ERROR',
    messageTitle: 'An error occurred',
    pageActionID: 'FIND_VEHICLE_PAGE_CHECK_ELIGIBILITY',
    messageDescription:
      'We could not complete this renewal online due to a technical error. You can try again later, call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre" target="_blank" rel="noopener noreferrer">service centre</a>. We apologise for any inconvenience.',
  },
];
export const PENSIONER_USAGE_TYPE_CODES = ['PNIC', 'PNSR'];
export const PENSIONER_DESCRIPTION = 'Pensioner Concession';
export const HVIS_CONDITION_CATEGORY_CODES = ['RHR', 'RHV', 'V91'];
export const NO_CARD_ENTERED = 'Please enter a card number and try again';
export const INVALID_CARD_MAX10CHAR =
  'Invalid card number. Please enter a maximum of 10 characters';
export const FIND_VEHICLE_INVALID_INPUT_NO_NUMBER_ENTERED =
  'No number was entered. Please enter a number plate or billing number.';
export const FIND_VEHICLE_INVALID_INPUT_SPECIAL_CHARACTER_ENTERED =
  'You have entered special characters. Please only enter numbers and letters.';
export const FIND_VEHICLE_INVALID_INPUT_EXCEED_MAXIMUM_CHARACTERS =
  'Invalid number. Please enter a maximum of 8 characters.';
export const FIND_VEHICLE_INVALID_PLATE_OR_BILLING_NUMBER_INPUT =
  'Invalid plate number or billing number format. Please check and try again.';
export const CAN_I_CHANGE_TERM_HEADING = 'Can I change the renewal term?';
export const TRAILER_CODES = ['DT', 'OT'];
export const PRESET_VEOS_CONTRIBUTION_AMOUNTS_WITH_TIME = [
  { amount: '20', time: 1.3 },
  { amount: '40', time: 1.8 },
  { amount: '60', time: 3 },
  { amount: '80', time: 4.5 },
];
export const MASK_BILLING_NUMBER_PREFIX = '*****';
export const MISSING_BILLING_NUMBER = '-';
export const VALID_PLATE_TYPES = ['O', 'M'];
export const AUTHENTICATED_LINKED_USER_TYPE = 'AuthenticatedLinked';
export const LT_VEHICLE = 'LT_VEHICLE';
export const LT_COMMERCIAL_VEHICLE = 'LT_COMMERCIAL_VEHICLE';
export const COMMERCIAL_VEHICLE = 'COMMERCIAL_VEHICLE';
export const MOTOR_BIKE = 'MOTOR_BIKE';
export const HEAVY_VEHICLE = 'HEAVY_VEHICLE';
export const GENERIC_TRAILER = 'GENERIC_TRAILER';
export const TRAILER_WITH_COVER = 'TRAILER_WITH_COVER';
export const AGRICULTURAL = 'AGRICULTURAL';
export const SNOW = 'SNOW';
export const GOLF = 'GOLF';
export const GENERIC_VEHICLE = 'GENERIC_VEHICLE';
export const BULLDOZER_CONSTRUCTION = 'BULLDOZER_CONSTRUCTION';
export const CRANE_CONSTRUCTION = 'CRANE_CONSTRUCTION';
export const TRAILER_CARAVAN = 'TRAILER_CARAVAN';
