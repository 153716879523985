import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { Button } from '@snsw/react-component-library';

export const serviceCenterPhoneNumber = isMobileOnly ? (
  <a href='tel:137788'>13 77 88</a>
) : (
  '13 77 88'
);

export const ButtonWithUrl = styled(Button)`
  ${() => css`
    text-decoration: none !important;
    padding-left: 1rem;
    padding-right: 1rem;
  `}
`;
