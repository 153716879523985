import React from 'react';
import { InPageAlert } from '@snsw/react-component-library';
import { sendEmailErrorNotification } from '../../../../utils/helpers/ErrorHandlers/helpers/errorContentDetails';

export const EmailNotificationError: React.FC<EmailNotificationErrorProps> = ({
  visible,
}) => {
  return (
    <>
      {visible && (
        <InPageAlert variant='error' title='Email failed to send'>
          {sendEmailErrorNotification}
        </InPageAlert>
      )}
    </>
  );
};

interface EmailNotificationErrorProps {
  visible: boolean;
}
