import React from 'react';
import { GenericErrorHandler } from '@rmstransactions/components';
import { Route, RouteProps } from 'react-router-dom';

interface RouteWrapperProps extends RouteProps {
  WrappedComponent: React.ComponentType;
}

export const RouteWrapper = ({
  WrappedComponent,
  ...rest
}: RouteWrapperProps): JSX.Element => {
  const CatchAllError = GenericErrorHandler('/failure/SYSTEM_FAILURE_GENERIC');

  return (
    <Route {...rest}>
      <CatchAllError>
        <WrappedComponent />
      </CatchAllError>
    </Route>
  );
};
