import React, { useRef, useEffect } from 'react';
import { useStoreSelector, useStoreDispatch } from 'redux/store';
import { useHistory } from 'react-router-dom';
import {
  Heading,
  HorizontalRule,
  Button,
  ContentContainer,
  Callout,
  InPageAlert,
} from '@snsw/react-component-library';
import { Col, Row } from '@rmstransactions/components/Styled';
import { ProgressStepper, Spacer } from '@rmstransactions/components';
import { stepsList } from '../../utils/progressBar/stepsList';
import CustomerDetails from '../../components/CustomerDetails/CustomerDetails';
import { ButtonGroup } from '../YourLicence/YourLicence.styled';
import { reviewDeclarationSliceActions } from 'redux/slice/reviewDeclaration';
import { LinkStyle } from './DeclarationPage.style';
import {
  getInactiveLicenceCategory,
  handleScrollToTop,
  hasDualClass,
  hasInactiveLicence,
  invokeSilentLoginIfSessionNotActive,
} from 'utils/helpers/helpers';
import { NotifySingleReplacementOnly } from './components/NotifySingleReplacementOnly';
import QuestionRadioGroup from './components/QuestionRadioGroup';
import { interceptors } from 'services/ReplaceLicenceService';

const calloutTitle =
  "You're required to complete a legal declaration about your eligibility to receive a replacement NSW Driver Licence.";

export const DeclarationPage: React.FC = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const ref = useRef(null);

  const {
    licenceCategory,
    isErrorInAnswer1,
    isErrorInAnswer2,
    selectedAnswer1,
    selectedAnswer2,
  } = useStoreSelector((state) => ({
    licenceCategory: state.licence.licenceCategory,
    selectedAnswer1: state.reviewDeclaration.answer1,
    selectedAnswer2: state.reviewDeclaration.answer2,
    isErrorInAnswer1: state.reviewDeclaration.errorInAnswer1,
    isErrorInAnswer2: state.reviewDeclaration.errorInAnswer2,
  }));

  const handleContinueToReviewClick = () => {
    if (!selectedAnswer1 || !selectedAnswer2) {
      dispatch(
        reviewDeclarationSliceActions.setErrorInAnswer1(!selectedAnswer1)
      );
      dispatch(
        reviewDeclarationSliceActions.setErrorInAnswer2(!selectedAnswer2)
      );
      return;
    }
    history.push('/review-and-confirm');
  };

  const handleBackButtonClick = () => {
    history.push('/licence');
  };

  const handleBrowserScrollToQuestion = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getErrorCountsDescription = () =>
    isErrorInAnswer1 && isErrorInAnswer2
      ? '2 items'
      : isErrorInAnswer1 || isErrorInAnswer2
      ? '1 item'
      : '';

  useEffect(() => {
    //Invokes access error page when logged out in multi-tab
    interceptors(history);
    //On refresh should take you to licence page
    if (licenceCategory === null) {
      history.push('/licence');
    }
    handleScrollToTop();
  }, []);

  useEffect(() => {
    invokeSilentLoginIfSessionNotActive();
  }, []);

  const inactiveCategory = getInactiveLicenceCategory(licenceCategory || []);

  return (
    <>
      <ProgressStepper
        stepsList={stepsList[1]}
        label='Replace Driver Licence'
        title='Declaration'
      />
      <ContentContainer>
        <Row>
          <Col lg={6} md={10}>
            {(isErrorInAnswer1 || isErrorInAnswer2) && (
              <Spacer mb={2.5}>
                <InPageAlert variant='error' title='Your page has errors'>
                  <Spacer mb={1}>
                    <p>
                      Please check the following {getErrorCountsDescription()}:
                    </p>
                  </Spacer>
                  <ul>
                    {isErrorInAnswer1 && (
                      <li>
                        <LinkStyle onClick={handleBrowserScrollToQuestion}>
                          Declaration question 1
                        </LinkStyle>
                      </li>
                    )}
                    {isErrorInAnswer2 && (
                      <li>
                        <LinkStyle onClick={handleBrowserScrollToQuestion}>
                          Declaration question 2
                        </LinkStyle>
                      </li>
                    )}
                  </ul>
                </InPageAlert>
              </Spacer>
            )}
            <CustomerDetails
              showLicenceCategory={
                licenceCategory &&
                hasDualClass(licenceCategory) &&
                hasInactiveLicence(licenceCategory)
              }
            />
            <HorizontalRule marginTop='3.5rem' marginBottom='3.5rem' />
            <Callout title={calloutTitle}>
              <p>
                Answer all questions truthfully. It is an offence to provide
                false or dishonest information.
              </p>
            </Callout>
            <Heading level={2}>Your declaration</Heading>

            <NotifySingleReplacementOnly
              showAlert={
                licenceCategory &&
                hasDualClass(licenceCategory) &&
                hasInactiveLicence(licenceCategory)
              }
              expiryDate={inactiveCategory?.expiryDate}
              inactiveStatus={inactiveCategory?.licenceStatus}
            />

            <div ref={ref}>
              <QuestionRadioGroup id={1} />
              <QuestionRadioGroup id={2} />
            </div>
          </Col>
        </Row>
        <Spacer mt={3.5} mb={1.5}>
          <ButtonGroup>
            <Button
              data-testid='continueToReviewBtn'
              onClick={handleContinueToReviewClick}
              className='button continue'
            >
              <b>Continue to review and confirm</b>
            </Button>
            <Button
              id='backBtm'
              data-testid='backbtn'
              theme='secondary'
              className='button back'
              onClick={handleBackButtonClick}
            >
              <b>Back</b>
            </Button>
          </ButtonGroup>
        </Spacer>
      </ContentContainer>
    </>
  );
};
