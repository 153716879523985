import { matchPath } from 'react-router-dom';
import {
  analyticsServiceV2,
  AnalyticsSessionManager,
} from '@rmstransactions/components';
import {
  generateTransactionInfo,
  googleAnalyticsDefaultInfo,
  googleAnalyticsUUIDAlmostDoneRedirectInfo,
  googleAnalyticsViewReceiptInfo,
} from 'utils/route/urlParams';
import { licenceTypeList } from 'utils/helpers/helpers';

const { getSessionID, getUserLoginType, getTransactionStartTime } =
  AnalyticsSessionManager;

let dynamicTransactionInfo;

type LicenceType = {
  code?: string;
};

const pushAnalyticsNewPage = (
  licenceTypeVehicleOne?: LicenceType | undefined,
  licenceTypeVehicleTwo?: LicenceType | undefined
): void => {
  const codeOne = licenceTypeVehicleOne?.code;
  const codeTwo = licenceTypeVehicleTwo?.code;

  dynamicTransactionInfo = generateTransactionInfo(
    licenceTypeList[codeOne]?.code,
    licenceTypeList[codeTwo]?.code
  );
  const hasRequiredSessionFields =
    getSessionID() && getUserLoginType() && getTransactionStartTime();
  if (!hasRequiredSessionFields) return;

  const transactionInfo = getNewPageTransactionInfo(dynamicTransactionInfo);
  const overridePageLocation = getOverridePageLocation();

  analyticsServiceV2.pushNewPageVisited({
    pageName: document?.title,
    transactionInfo: transactionInfo,
    overridePageLocation,
  });
};

export default pushAnalyticsNewPage;

export const getNewPageTransactionInfo = (
  dynamicTransactionInfo: string
): NewPageTransactionInfo => {
  const { pathname } = window.location;

  const isViewReceiptURL = matchPath(pathname, {
    path: '/view-receipt/:receiptUUID',
  });
  if (isViewReceiptURL) return googleAnalyticsViewReceiptInfo;

  const isUUIDAlmostDoneURL = matchPath(pathname, {
    path: '/almost-done/:receiptUUID',
  });
  if (isUUIDAlmostDoneURL) return googleAnalyticsUUIDAlmostDoneRedirectInfo;

  return Object.keys(dynamicTransactionInfo).includes(pathname)
    ? dynamicTransactionInfo[pathname]
    : googleAnalyticsDefaultInfo;
};

interface NewPageTransactionInfo {
  transaction_name: string;
  transaction_step: string;
  transaction_type: string;
  transaction_sub_type: string;
}

/**
 *  we don't want to send the "receiptUUID" value to Google Analytics
 *  therefore we will replace it manually
 */
export const getOverridePageLocation = (): string | undefined => {
  const { origin, pathname } = window.location;

  const isViewReceiptURL = matchPath(pathname, {
    path: '/view-receipt/:receiptUUID',
  });
  if (isViewReceiptURL) return `${origin}/view-receipt/uuid`;

  const isUUIDAlmostDoneURL = matchPath(pathname, {
    path: '/almost-done/:receiptUUID',
  });
  if (isUUIDAlmostDoneURL) return `${origin}/almost-done/uuid`;

  return undefined;
};
