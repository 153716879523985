import React, { useEffect, useRef, useState } from 'react';
import { useStoreSelector, useStoreDispatch } from 'redux/store';
import { useHistory } from 'react-router-dom';
import {
  ProgressStepper,
  FormRadioGroupRms,
  Spacer,
  UserSession,
} from '@rmstransactions/components';
import {
  Heading,
  HorizontalRule,
  InPageAlert,
  Button,
  ContentContainer,
  MoreInfoModal,
  FormGroup,
  Input,
  SectionPage,
} from '@snsw/react-component-library';
import { Col, Row } from '@rmstransactions/components/Styled';
import {
  CardLostInfo,
  CardValidationInfo,
  MoreInfoModalWrapper,
} from './YourLicence.styled';
import { stepsList } from '../../utils/progressBar/stepsList';
import CustomerDetails from '../../components/CustomerDetails/CustomerDetails';
import { ButtonGroup } from './YourLicence.styled';
import {
  gotoDrupal,
  handleScrollToTop,
  invokeSilentLoginIfSessionNotActive,
  logoutAndResetUserSession,
  validateInputValue,
} from 'utils/helpers/helpers';
import { licenceSliceActions } from '../../redux/slice/licence';
import { customerSliceActions } from '../../redux/slice/customer';
import { eligibilitySliceActions } from '../../redux/slice/eligibility';
import { validateSliceActions } from '../../redux/slice/validate';
import {
  searchAndGetDeclarations,
  interceptors,
} from 'services/ReplaceLicenceService';
import { reviewDeclarationSliceActions } from 'redux/slice/reviewDeclaration';
import { getDeclarationsForm } from 'services/ReplaceLicenceService';
import { declarationFormSliceActions } from 'redux/slice/declarationForm';

import {
  handleCustomerDetails,
  handleLicenceDetails,
  handleEligibilityDetails,
  handleValidateDetails,
  concessionCardTypeSelection,
  ConsessionCardType,
  concessionCardErrors,
  ConcessionCardTypeDefinition,
} from './YourLicenceHelper';

import HelpText from './components/HelpText';
import { concessionCardSliceActions } from '../../redux/slice/concessionCard';

import NotifyCustomer from './components/NotifyCustomer';
import {
  notEligibleForPensionConssesionTitle,
  unableToverifyConssesionDescription,
} from 'utils/helpers/ErrorHandlers/helpers/errorContentDetails';
import { ErrorCodes } from 'utils/helpers/ErrorHandlers/helpers/helpers';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import { NotifyWarningsAndErrors } from './components/NotifyWarningsAndErrors';
import { ReplacementReasons } from './components/ReplacementReasons';

interface IValidationError {
  validationError: boolean;
  showValidationError: boolean;
}

const ShowConcessionInvalidWarning = ({ showConcessionWarning }) => {
  return (
    showConcessionWarning && (
      <Spacer mt={2} mb={3.5}>
        <NotifyCustomer
          title={notEligibleForPensionConssesionTitle}
          details={unableToverifyConssesionDescription}
        />
      </Spacer>
    )
  );
};

const YourLicence: React.FC = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();

  const setConcessionCard = (cardType: ConcessionCardTypeDefinition) => {
    dispatch(concessionCardSliceActions.setConcessionType(cardType));
    setNoConcessionSelected(false);
  };

  const [cardInputValidationError, setCardInputValidationError] =
    useState<IValidationError>({
      validationError: false,
      showValidationError: false,
    });

  const [concessionCardNumber, setConcessionCardNumber] = useState<
    string | null
  >(null);
  const [dvaCardNumber, setDvaCardNumber] = useState<string | null>(null);
  const [continueButtonSelected, setContinueButtonSelected] =
    useState<boolean>(false);

  const [showConcessionCardInvalid, setShowConcessionCardInvalid] =
    useState<boolean>(false);
  const [noReasonSelected, setNoReasonSelected] = useState<boolean>(false);
  const [noConcessionSelected, setNoConcessionSelected] =
    useState<boolean>(false);

  const {
    licenceNumber,
    priceDetails,
    givenName,
    rmsCustomerId,
    replacementReason,
    concessionCardType,
    isError,
    isErrorInValidateResponse,
    errorCodes,
    warningCodes,
    existingDeclaration,
    licenceCategoryEligibility,
  } = useStoreSelector((state) => ({
    licenceNumber: state?.licence?.licenceNumber,
    priceDetails: state.validate.priceDetails,
    givenName: state.customer.givenName,
    rmsCustomerId: state.customer.customerId,
    isError: state.eligibility.isError,
    isErrorInValidateResponse: state.validate.isErrorInValidateResponse,
    errorCodes: state.eligibility.eligibilityIdentifier,
    warningCodes: state.eligibility.warningCodes,
    replacementReason: state.validate.replacementReason,
    existingDeclaration: state.validate.existingDeclaration,
    licenceCategoryEligibility: state.eligibility.licenceCategoryEligibility,
    reasons: state.eligibility,
    concessionCardType: state.concessionCard.concessionType,
  }));
  const hasSelectedConcessionCard = () =>
    concessionCardType === ConsessionCardType.CENTRELINK;
  const hasSelectedNoConcessionCard = () =>
    concessionCardType === ConsessionCardType.NO_CONCESSION;
  const hasSelectedPensionerCard = () =>
    concessionCardType === ConsessionCardType.DEPARTMENT_OF_VETERAN_AFFAIRS;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const licenceRef = useRef<HTMLDivElement | null>(null);
  const concessionRef = useRef<HTMLDivElement | null>(null);

  const setCardNo: (
    cardNo: string,
    isCentreLinkOrPensioner: boolean
  ) => void = (cardNo, isCentreLinkOrPensioner) => {
    if (isCentreLinkOrPensioner) {
      setConcessionCardNumber(cardNo);
      dispatch(concessionCardSliceActions.setConcessionCardNumber(cardNo));
      setDvaCardNumber(null);
    } else {
      setDvaCardNumber(cardNo);
      dispatch(concessionCardSliceActions.setConcessionCardNumber(cardNo));
      setConcessionCardNumber(null);
    }
  };

  const errorMessage = showConcessionCardInvalid
    ? 'This concession card number is not valid'
    : 'Please enter a valid concession card number.';

  const validateConcessionCardNumber = (
    cardNo: string,
    isCentreLinkOrPensioner: boolean
  ) => {
    const concessionCardNoError = validateInputValue(cardNo);
    if (concessionCardErrors.includes(concessionCardNoError)) {
      // If card length is zero we don't want to retain the last
      // character and therefore would set it as empty string
      if (cardNo.length === 0) {
        setCardNo(cardNo, isCentreLinkOrPensioner);
      }
      // If card length is greater than twelve we truncate the extra characters
      // and therefore not show the input validation error
      if (cardNo.length > 12) {
        // this will also truncate the extra characters when it is pasted
        // from clipboard
        setCardNo(cardNo.slice(0, 12), isCentreLinkOrPensioner);
        setCardInputValidationError({
          validationError: false,
          showValidationError: true,
        });
      } else {
        setCardInputValidationError({
          validationError: true,
          showValidationError: true,
        });
      }
      return;
    }
    setCardInputValidationError({
      validationError: false,
      showValidationError: false,
    });
    setCardNo(cardNo, isCentreLinkOrPensioner);
    setShowConcessionCardInvalid(false);
  };

  interface IHandleValidateReturn {
    hasValidationError: boolean;
    hasExistingDeclaration: boolean;
  }

  const handleValidate = async (
    concessionType: ConcessionCardTypeDefinition | '' = concessionCardType,
    reason: string = replacementReason
  ): Promise<IHandleValidateReturn> => {
    try {
      if (!concessionType) return;

      const {
        priceDetails,
        pensionerDiscountFlag,
        deferredPensionerCheck,
        systemMessages,
        validateErrorCodes,
        isErrorInValidateResponse,
        validateWarningCodes,
        existingDeclaration,
        declarationId,
        showInfoArray,
      } = await handleValidateDetails(
        reason,
        concessionCardNumber || dvaCardNumber,
        givenName,
        concessionType,
        false,
        null
      );

      dispatch(
        validateSliceActions.setDeclarationDetails({
          existingDeclaration: existingDeclaration,
          declarationId: declarationId,
        })
      );
      dispatch(
        validateSliceActions.setSystemMessagesWithBusinessError({
          systemMessages: systemMessages,
          validationErrorCodes: validateErrorCodes,
          isErrorInValidateResponse: isErrorInValidateResponse,
          validateWarningCodes: validateWarningCodes,
          isWarningInValidateCall: validateWarningCodes.length > 0,
          showInfoArray: showInfoArray,
        })
      );

      const isWarningCodeValid = validateWarningCodes.includes(
        'PENSIONER_CHECK_DEFERRED_ON_PENSIONER_VALIDATION'
      );
      const isEligibleForPensiner =
        pensionerDiscountFlag === 'Y' ||
        (pensionerDiscountFlag === 'N' && deferredPensionerCheck === 'Y') ||
        isWarningCodeValid;

      const isInvalidCardNumber =
        (continueButtonSelected &&
          (hasSelectedConcessionCard || hasSelectedPensionerCard) &&
          !isEligibleForPensiner) ||
        validateWarningCodes.includes(
          ErrorCodes.NOT_ELIGIBLE_FOR_PENSION_CONCESSION
        );

      setShowConcessionCardInvalid(isInvalidCardNumber);
      continueButtonSelected
        ? (dispatch(
            validateSliceActions.setPensionerDiscountFlag(pensionerDiscountFlag)
          ),
          dispatch(validateSliceActions.setPriceDetails(priceDetails)),
          dispatch(
            validateSliceActions.setDeferredPensionerCheck(
              deferredPensionerCheck
            )
          ))
        : dispatch(validateSliceActions.setPriceDetails(priceDetails));

      setCardInputValidationError({
        validationError: isErrorInValidateResponse || isInvalidCardNumber,
        showValidationError: isInvalidCardNumber,
      });

      return {
        hasValidationError: isErrorInValidateResponse || isInvalidCardNumber,
        hasExistingDeclaration: existingDeclaration?.toLowerCase() === 'y',
      };
    } catch (error) {
      history.push('/failure/SYSTEM_FAILURE_GENERIC');
    }
  };

  const handleCustomer = async () => {
    try {
      const customerResponse = await handleCustomerDetails();
      if (customerResponse && !customerResponse.systemMessages) {
        dispatch(
          customerSliceActions.setCustomerDetails({
            customerId: customerResponse.customerId,
            unstructuredName: customerResponse.unstructuredName,
            givenName: customerResponse.givenName,
            familyName: customerResponse.familyName,
          })
        );
      } else if (customerResponse?.systemMessages?.message[0]?.identifier) {
        history.push(
          `/failure/${customerResponse.systemMessages.message[0].identifier}`
        );
      }
    } catch (error) {
      history.push('/failure/SYSTEM_FAILURE_GENERIC');
    }
  };

  const handleLicence = async () => {
    try {
      const licenceResponse = await handleLicenceDetails();
      if (licenceResponse && !licenceResponse.systemMessages) {
        dispatch(
          licenceSliceActions.setLicenceDetails({
            licenceCategory: licenceResponse.licenceCategory,
            licenceNumber: licenceResponse.licenceNumber,
          })
        );
      } else if (licenceResponse?.systemMessages?.message[0]?.identifier) {
        history.push(
          `/failure/${licenceResponse.systemMessages.message[0].identifier}`
        );
      }
    } catch (error) {
      history.push('/failure/SYSTEM_FAILURE_GENERIC');
    }
  };

  const handleEligibility = async () => {
    try {
      const eligibilityResponse = await handleEligibilityDetails();
      if (eligibilityResponse) {
        setIsLoading(false);
        dispatch(
          eligibilitySliceActions.setEligibilityDetails({
            eligibilityStatus:
              eligibilityResponse?.replaceLicenceEligibilityStatus,
            replacementReasons: eligibilityResponse.replacementReasons,
          })
        );
        dispatch(
          eligibilitySliceActions.setSystemMessagesWithBusinessError({
            eligibilityIdentifier: eligibilityResponse?.eligibilityErrorCode,
            licenceStatus: eligibilityResponse?.licenceStatus,
            isError: eligibilityResponse?.isError,
            showInfoArray: eligibilityResponse?.showInfoArray,
            warningCodes: eligibilityResponse?.warningCodes,
            licenceCategoryEligibility:
              eligibilityResponse.licenceCategoryEligibility,
          })
        );

        if (
          eligibilityResponse?.replaceLicenceEligibilityStatus?.toUpperCase() ===
          'Y'
        ) {
          handleDeclarationsForm();
          callValidation(concessionCardType, replacementReason);
        }
      }
    } catch (error) {
      history.push('/failure/SYSTEM_FAILURE_GENERIC');
    }
  };

  const handleSearchAndGetDeclarationsDetails = async (
    hasExistingDeclaration: boolean
  ) => {
    try {
      if (hasExistingDeclaration) {
        const response = await searchAndGetDeclarations();
        const searchDeclarationDetails = response.data.declaration;
        dispatch(
          reviewDeclarationSliceActions.setAnswer1(
            searchDeclarationDetails[0].response.answer[0]
          )
        );
        dispatch(
          reviewDeclarationSliceActions.setAnswer2(
            searchDeclarationDetails[1].response.answer[0]
          )
        );
        history.push('/review-and-confirm');
      } else {
        history.push('/declaration');
      }
    } catch (error) {
      history.push('/failure/SYSTEM_FAILURE_GENERIC');
    }
  };

  const handleDeclarationsForm = async () => {
    try {
      const response = await getDeclarationsForm();
      if (response) {
        const questions = response.data.questions;
        questions.map((question) => {
          if (question.id === 16) {
            dispatch(
              declarationFormSliceActions.setQuestion1(`Since last obtaining your licence, have you been
          prohibited or refused from driving a motor
          vehicle/vessel or riding a motorcycle in NSW or
          elsewhere?`)
            );
          } else {
            dispatch(
              declarationFormSliceActions.setQuestion2(`Since last obtaining your licence, have you been
            disqualified, cancelled or suspended, is there a charge
            pending against you, or is your licence subject to an
            appeal for driving, riding or maritime boating offences?`)
            );
          }
        });
      }
    } catch (error) {
      history.push('/failure/SYSTEM_FAILURE_GENERIC');
    }
  };

  const handleContinueClick = async () => {
    if (!replacementReason || !concessionCardType) {
      setNoReasonSelected(!replacementReason);
      setNoConcessionSelected(!concessionCardType);
      return;
    }

    setContinueButtonSelected(true);
    if (
      (!concessionCardNumber && hasSelectedConcessionCard()) ||
      (!dvaCardNumber && hasSelectedPensionerCard())
    ) {
      setCardInputValidationError({
        validationError: true,
        showValidationError: true,
      });
      return;
    }
  };

  const callValidation = async (
    concessionType: ConcessionCardTypeDefinition | '' = concessionCardType,
    reason: string | ''
  ) => {
    if (
      concessionType &&
      reason &&
      concessionType === ConsessionCardType.NO_CONCESSION
    ) {
      setIsLoading(true);
      await handleValidate(concessionType, reason);
      setIsLoading(false);
    }
  };

  const handleRadioClick = async (
    selectedType: ConcessionCardTypeDefinition
  ) => {
    setCardInputValidationError({
      validationError: false,
      showValidationError: false,
    });

    setConcessionCard(selectedType);

    dispatch(concessionCardSliceActions.setConcessionType(selectedType));
    callValidation(selectedType, replacementReason);
  };

  const manageContinue = async (concessionCardType) => {
    if (hasSelectedNoConcessionCard()) {
      handleSearchAndGetDeclarationsDetails(existingDeclaration === 'Y');
    }
    if (
      (hasSelectedConcessionCard() && concessionCardNumber) ||
      (hasSelectedPensionerCard() && dvaCardNumber)
    ) {
      const {
        hasValidationError: isInvalidConcessionCard,
        hasExistingDeclaration,
      } = await handleValidate(concessionCardType);
      if (!(showConcessionCardInvalid || isInvalidConcessionCard)) {
        handleSearchAndGetDeclarationsDetails(hasExistingDeclaration);
      }
    }
  };

  const handleQuitButton = async () => {
    try {
      await logoutAndResetUserSession();
    } catch (error) {
      // Handle error if needed
    } finally {
      gotoDrupal();
    }
  };

  const handleCustomerAndLicenceDetails = async () => {
    await handleCustomer();
    await handleLicence();
  };

  useEffect(() => {
    //Invokes access error page when logged out in multitab
    interceptors(history);
    const cleanup = () => {
      setContinueButtonSelected(false);
    };
    if (isError || isErrorInValidateResponse) {
      history.push('/error');
      return cleanup;
    } else {
      if (continueButtonSelected && replacementReason && concessionCardType)
        manageContinue(concessionCardType);

      setContinueButtonSelected(false);
      return cleanup;
    }
  }, [
    concessionCardType,
    concessionCardNumber,
    dvaCardNumber,
    continueButtonSelected,
    isError,
    isErrorInValidateResponse,
    existingDeclaration,
    history,
  ]);

  useEffect(() => {
    UserSession.resetLoggedOutUser();
    setIsLoading(true);

    if (!licenceNumber) {
      handleCustomerAndLicenceDetails();
    }

    if (licenceNumber && rmsCustomerId) {
      handleEligibility();
    }
    handleScrollToTop();
  }, [licenceNumber]);

  useEffect(() => {
    invokeSilentLoginIfSessionNotActive();
  }, []);

  return (
    <>
      <ProgressStepper
        stepsList={stepsList[0]}
        label='Replace Driver Licence'
        title='Your Licence'
      />

      <ContentContainer>
        <SectionPage>
          <Row>
            <Col lg={6} md={10}>
              <NotifyWarningsAndErrors
                warningCodes={warningCodes}
                errorCodes={errorCodes}
                noConcessionSelection={noConcessionSelected}
                noReasonSelection={noReasonSelected}
                concessionRef={concessionRef}
                licenceRef={licenceRef}
                licenceCategoryEligibility={licenceCategoryEligibility}
              />

              <CustomerDetails showLicenceCategory />
              <HorizontalRule marginTop='2.5rem' marginBottom='2.5rem' />
              <div ref={licenceRef}>
                <Heading level={3}>
                  What happened to your NSW Driver Licence card?
                </Heading>
              </div>
              <Spacer mt='-1rem'>
                <ReplacementReasons
                  validateFunction={callValidation}
                  noReasonSelected={noReasonSelected}
                  setNoReasonSelected={setNoReasonSelected}
                  replacementReason={replacementReason}
                />
              </Spacer>
              <Spacer mt={1}>
                <MoreInfoModalWrapper>
                  <MoreInfoModal
                    linkText='Card not lost, stolen, destroyed or impacted by data breach?'
                    questionHyperLink
                    title='My NSW Driver Licence card was not lost, stolen, destroyed or impacted by data breach.
                  What do I do?'
                    helpText={<HelpText />}
                  />
                </MoreInfoModalWrapper>
              </Spacer>
              <Spacer mt='3.5rem'>
                <div ref={concessionRef}>
                  <Heading level={3}>
                    Do you hold a NSW Pensioner Concession Card?
                  </Heading>
                </div>
                <Spacer mt='-1rem'>
                  <FormRadioGroupRms
                    id='concession-card-radio-group-0'
                    data-testid='concessionCardRadio1'
                    name='Do_you_hold_a_NSW_Pensioner_Concession_Card?'
                    className='radio-group'
                    legend=''
                    options={concessionCardTypeSelection.slice(0, 1)}
                    vertical
                    value={concessionCardType}
                    hasError={false}
                    errorMessage=''
                    errorTitle=''
                    onChange={handleRadioClick}
                    hasErrorInline={noConcessionSelected}
                  />
                  <FormRadioGroupRms
                    id='concession-card-radio-group-1'
                    data-testid='concessionCardRadio1'
                    name='Do_you_hold_a_NSW_Pensioner_Concession_Card?'
                    className='radio-group'
                    legend=''
                    options={concessionCardTypeSelection.slice(1, 2)}
                    vertical
                    value={concessionCardType}
                    hasError={false}
                    errorMessage=''
                    errorTitle=''
                    onChange={handleRadioClick}
                    hasErrorInline={noConcessionSelected}
                  />
                </Spacer>

                {hasSelectedConcessionCard() && (
                  <>
                    <FormGroup
                      id='formGroup1'
                      hasError={cardInputValidationError.showValidationError}
                      label='Card number'
                      isRequired
                      errorMessage={errorMessage}
                      helpMessage={
                        <>
                          <CardValidationInfo>
                            Enter your concession card number without spaces,
                            symbols or dashes.
                          </CardValidationInfo>
                          <CardLostInfo>
                            {`If you have lost your card please contact Centrelink on 132 300.`}
                          </CardLostInfo>
                        </>
                      }
                    >
                      <Input
                        onChange={(
                          evt: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          evt.preventDefault();
                          validateConcessionCardNumber(evt.target.value, true);
                        }}
                        value={concessionCardNumber}
                        className='input'
                        data-testid='concessionInput'
                      />
                    </FormGroup>
                  </>
                )}
                <FormRadioGroupRms
                  id='concession-card-radio-group-2'
                  data-testid='concessionCardRadio2'
                  name='Do_you_hold_a_NSW_Pensioner_Concession_Card?'
                  className='radio-group'
                  legend=''
                  options={concessionCardTypeSelection.slice(2)}
                  vertical
                  value={concessionCardType}
                  hasError={false}
                  errorMessage='Please select an option above to continue'
                  errorTitle=''
                  onChange={handleRadioClick}
                  hasErrorInline={noConcessionSelected}
                />
              </Spacer>
              {hasSelectedPensionerCard() && (
                <>
                  <FormGroup
                    id='formGroup2'
                    hasError={cardInputValidationError.showValidationError}
                    label='Card number'
                    name='pensioner_card_number'
                    isRequired
                    errorMessage={errorMessage}
                    helpMessage={
                      <>
                        <CardValidationInfo>
                          Enter your concession card number without spaces,
                          symbols or dashes.
                        </CardValidationInfo>
                        <CardLostInfo>
                          {`If you have lost your card please contact the Department of
                        Veterans' Affairs on 1800 838 372.`}
                        </CardLostInfo>
                      </>
                    }
                  >
                    <Input
                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        evt.preventDefault();
                        validateConcessionCardNumber(evt.target.value, false);
                      }}
                      value={dvaCardNumber}
                      className='input'
                      data-testid='dvaCardInput'
                    />
                  </FormGroup>
                </>
              )}

              {hasSelectedNoConcessionCard() &&
                Number(priceDetails?.priceDetails?.grossAmount) >= 0 && (
                  <InPageAlert variant='info' title='Replacement fee'>
                    <p>
                      The replacement fee for your new NSW Driver Licence card
                      will be ${priceDetails?.priceDetails?.grossAmount}.
                    </p>
                  </InPageAlert>
                )}

              {(hasSelectedConcessionCard() || hasSelectedPensionerCard()) &&
                !showConcessionCardInvalid && (
                  <Spacer mt='2.5rem'>
                    <InPageAlert variant='info' title='Free replacement card'>
                      <p>
                        As a NSW Pensioner Concession Card holder your NSW
                        Driver Licence card replacement is free of charge.
                      </p>
                    </InPageAlert>
                  </Spacer>
                )}
              <ShowConcessionInvalidWarning
                showConcessionWarning={
                  showConcessionCardInvalid &&
                  !hasSelectedNoConcessionCard() &&
                  cardInputValidationError.showValidationError
                }
              />

              <Spacer mt={3.5} mb={1.5}>
                <ButtonGroup>
                  <Button
                    data-testid='continueBtn'
                    onClick={handleContinueClick}
                    className='button continue'
                  >
                    <b>Continue</b>
                  </Button>
                  <Button
                    id='quitbtn'
                    data-testid='quitbtn'
                    theme='secondary'
                    className='button quit'
                    onClick={handleQuitButton}
                  >
                    <b>Quit</b>
                  </Button>
                </ButtonGroup>
              </Spacer>
            </Col>
          </Row>
        </SectionPage>
      </ContentContainer>
      <LoadingOverlay visible={isLoading} />
    </>
  );
};

export default YourLicence;
