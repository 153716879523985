import IValidateReplaceLicenceRequest from '../../interfaces/api/request/IValidateReplaceLicenceRequest';
import {
  getCustomerDetailsByRmsUserId,
  getLicenceDetailsByRmsCustomerId,
  getEligibility,
  validateLicence,
} from 'services/ReplaceLicenceService';
import { IConcessionDetails } from '../../interfaces/api/request/IValidateReplaceLicenceRequest';
import { ILicenceCategoryLicenceApi } from 'interfaces/api/response/ILicence';
import { ISystemMessages } from '../../interfaces/api/response/IMessage';
import { IValidationRequestResponseDetailsPriceDetails } from 'interfaces/api/response/IValidate';

import { IDeclarationDetails } from '../../interfaces/api/request/IDeclarationDetails';
import {
  checkForWarningsAndErrors,
  checkForWarningsAndErrorsInSystemMessages,
} from 'utils/helpers/checkForWarningsAndErrors';
import { IMessage } from '../../interfaces/api/response/IMessage';
import {
  IInfo,
  matchCodeInMessages,
} from 'utils/helpers/ErrorHandlers/helpers/helpers';
import { initialState } from 'redux/slice/eligibility';

export const handleCustomerDetails = async (): Promise<{
  customerId: string;
  unstructuredName: string;
  givenName: string;
  familyName: string;
  systemMessages?: ISystemMessages;
}> => {
  try {
    const response = await getCustomerDetailsByRmsUserId();
    return response.data;
  } catch (error) {
    return null;
  }
};

export const handleLicenceDetails = async (): Promise<{
  licenceCategory: ILicenceCategoryLicenceApi[];
  licenceNumber: string;
  systemMessages?: ISystemMessages;
}> => {
  try {
    const response = await getLicenceDetailsByRmsCustomerId();
    const licenceCategory = response.data.licenceCategory;
    const licenceNumber = response.data.licenceNumber;
    const systemMessages = response.data.systemMessages;
    return {
      licenceCategory,
      licenceNumber,
      systemMessages,
    };
  } catch (error) {
    return;
  }
};

export const handleEligibilityDetails = async (): Promise<{
  replacementReasons: string[];
  replaceLicenceEligibilityStatus: string;
  licenceStatus: string[];
  eligibilityErrorCode: string[];
  isError: boolean;
  showInfoArray: IInfo[];
  warningCodes: string[];
  licenceCategoryEligibility: ILicenceCategoryLicenceApi[];
}> => {
  try {
    const response = await getEligibility();
    const replaceLicenceEligibilityStatus = response.data.eligibilityStatus;
    const licenceCategoryEligibility = response.data.licenceCategory;
    const replacementReasons = response.data.validReplacementReason;
    const {
      hasError,
      errorCodes: eligibilityErrorCode,
      licenceStatusForErrorCodes,
      warningCodes,
      showInfoArray,
    } = checkForWarningsAndErrors(licenceCategoryEligibility);

    const isError =
      hasError &&
      eligibilityErrorCode.length >= licenceCategoryEligibility.length;

    const { errorCodes: systemErrorCodes, warningCodes: systemWarningCodes } =
      checkForWarningsAndErrorsInSystemMessages(
        response.data.systemMessages?.message
      );

    if (systemErrorCodes.length > 0 && !isError) {
      return {
        replacementReasons,
        replaceLicenceEligibilityStatus,
        licenceStatus: licenceStatusForErrorCodes,
        eligibilityErrorCode: systemErrorCodes,
        isError: true,
        showInfoArray: [],
        warningCodes: systemWarningCodes,
        licenceCategoryEligibility: [],
      };
    }

    return {
      replacementReasons,
      replaceLicenceEligibilityStatus,
      licenceStatus: licenceStatusForErrorCodes,
      eligibilityErrorCode,
      isError,
      showInfoArray,
      warningCodes,
      licenceCategoryEligibility,
    };
  } catch (error) {
    return {
      replacementReasons: initialState.replacementReasons,
      replaceLicenceEligibilityStatus: '',
      licenceStatus: null,
      eligibilityErrorCode: ['SYSTEM_FAILURE_GENERIC'],
      isError: true,
      showInfoArray: [],
      warningCodes: [],
      licenceCategoryEligibility: [],
    };
  }
};

interface IHandleValidateDetailsResponse {
  pensionerDiscountFlag: string | null;
  deferredPensionerCheck?: string;
  priceDetails: IValidationRequestResponseDetailsPriceDetails | null;
  systemMessages: ISystemMessages;
  validateErrorCodes: string[];
  isErrorInValidateResponse: boolean;
  validateWarningCodes: string[];
  existingDeclaration?: string;
  declarationId?: string;
  showInfoArray: IInfo[];
}

export const handleValidateDetails = async (
  replacementReason: string,
  concessionCardNumber: string,
  givenName: string,
  concessionCardType: ConcessionCardTypeDefinition,
  submitButton: boolean,
  declarationDetails: IDeclarationDetails
): Promise<IHandleValidateDetailsResponse> => {
  const concessionDetails: IConcessionDetails = {
    number: concessionCardNumber,
    type: concessionCardType,
    givenName: givenName,
  };

  const validateReplaceLicenceRequest: IValidateReplaceLicenceRequest = {
    validationType: submitButton === false ? 'INITIALQUOTE' : 'FULLVALIDATION',
    replacementReason: replacementReason.replace(' ', '_').toUpperCase(),
    concessionDetails:
      concessionCardType === ConsessionCardType.NO_CONCESSION
        ? null
        : concessionDetails,
    declarationDetails: declarationDetails,
  };

  try {
    const {
      data: {
        validationRequestResponseDetails: response,
        licenceCategory: category1,
        systemMessages: systemMessages,
      },
    } = await validateLicence(validateReplaceLicenceRequest);

    const pensionerDiscountFlag = response?.priceDetails?.pensionerDiscountFlag;
    const deferredPensionerCheck = response?.deferredPensionerCheck;
    const priceDetails = response?.priceDetails;
    const existingDeclaration = response?.existingDeclaration;
    const declarationId = response?.declarationID;
    const licenceCategory = response?.licenceCategory;

    const {
      hasError: isErrorInValidateResponse,
      warningCodes: validateWarningCodes,
      errorCodes: validateErrorCodes,
      showInfoArray,
    } = checkForWarningsAndErrors(response?.licenceCategory || category1);

    const { errorCodes: systemErrorCodes, warningCodes: systemWarningCodes } =
      checkForWarningsAndErrorsInSystemMessages(systemMessages?.message);

    if (systemErrorCodes.length > 0 && !isErrorInValidateResponse) {
      return {
        pensionerDiscountFlag: null,
        priceDetails: null,
        systemMessages,
        validateErrorCodes: systemErrorCodes,
        isErrorInValidateResponse: true,
        validateWarningCodes: systemWarningCodes,
        showInfoArray: [],
      };
    }

    return {
      pensionerDiscountFlag,
      deferredPensionerCheck,
      priceDetails,
      systemMessages,
      validateErrorCodes,
      isErrorInValidateResponse:
        isErrorInValidateResponse &&
        validateErrorCodes.length >= (licenceCategory || category1).length,
      validateWarningCodes,
      existingDeclaration,
      declarationId,
      showInfoArray,
    };
  } catch (error) {
    return {
      pensionerDiscountFlag: '',
      deferredPensionerCheck: '',
      priceDetails: null,
      systemMessages: null,
      validateErrorCodes: ['SYSTEM_FAILURE_GENERIC'],
      isErrorInValidateResponse: true,
      validateWarningCodes: [],
      existingDeclaration: '',
      declarationId: '',
      showInfoArray: [],
    };
  }
};
export type ConcessionCardTypeDefinition =
  | 'NO_CONCESSION'
  | 'CENTRELINK'
  | 'DEPARTMENT_OF_VETERAN_AFFAIRS';

export const ConsessionCardType: Record<ConcessionCardTypeDefinition, string> =
  {
    NO_CONCESSION: 'NO_CONCESSION',
    CENTRELINK: 'CENTRELINK',
    DEPARTMENT_OF_VETERAN_AFFAIRS: 'DEPARTMENT_OF_VETERAN_AFFAIRS',
  };

export const concessionCardTypeSelection = [
  {
    label: 'No concession',
    value: ConsessionCardType.NO_CONCESSION,
  },
  {
    label: 'Centrelink',
    value: ConsessionCardType.CENTRELINK,
  },
  {
    label: "Department of Veterans' Affairs",
    value: ConsessionCardType.DEPARTMENT_OF_VETERAN_AFFAIRS,
  },
];

export const concessionCardErrors = [
  'SPECIAL_CHARACTER',
  'MAX_LENGTH_REACHED',
  'EMPTY_CONCESSION_CARD_NOT_ALLOWED',
];

export const findWarningInLicenceCategory = (
  messages: IMessage[] | IMessage | undefined
): IMessage | undefined => {
  return matchCodeInMessages(messages, 'severity', 'WARNING') as IMessage;
};

export const findErrorInLicenceCategory = (
  messages: IMessage[] | IMessage | undefined
): IMessage | undefined => {
  return matchCodeInMessages(messages, 'severity', 'ERROR') as IMessage;
};
