import styled from 'styled-components';

export const FeesBreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const FeesBreakdownRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 16px;
  :last-child {
    font-size: 18px;
    margin-bottom: 0;
  }
`;
