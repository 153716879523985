import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { validateReducer } from './slice/validate';
import { customerReducer } from './slice/customer';
import { licenceReducer } from './slice/licence';
import { eligibilityReducer } from './slice/eligibility';
import { userTypeReducer } from './slice/userType';
import { reviewDeclarationReducer } from './slice/reviewDeclaration';
import { declarationFormReducer } from './slice/declarationForm';
import { concessionCardReducer } from './slice/concessionCard';
import { confirmationReducer } from './slice/confirmation';
import { interimLicenceReducer } from './slice/interimLicence';

const reducers = combineReducers({
  validate: validateReducer,
  customer: customerReducer,
  licence: licenceReducer,
  eligibility: eligibilityReducer,
  userType: userTypeReducer,
  reviewDeclaration: reviewDeclarationReducer,
  declarationForm: declarationFormReducer,
  concessionCard: concessionCardReducer,
  interimLicence: interimLicenceReducer,
  confirmation: confirmationReducer,
});

export const store = configureStore({
  reducer: reducers,
});

export type ReducerRootState = ReturnType<typeof reducers>;
export type ReducersStoreType = typeof store;
export type StoreType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useStoreDispatch: () => AppDispatch = useDispatch;
export const useStoreSelector: TypedUseSelectorHook<StoreType> = useSelector;
const storeInitialState = store.getState();

export const setupReduxStore = (
  preloadedState?: Partial<PreloadedState<ReducerRootState>>
): ReducersStoreType => {
  return configureStore({
    reducer: reducers,
    preloadedState: {
      ...storeInitialState,
      ...preloadedState,
    },
  });
};
export default store;
