import React from 'react';
import { Spacer } from '@rmstransactions/components';
import {
  hasDualClass,
  hasInactiveLicence,
  hasAppealedSuspensionCancelExists,
} from 'utils/helpers/helpers';
import {
  InPageAlertHeaderOverride,
  InWarningList,
  InWarningUnorderedWrapper,
  TextLinkBlue,
} from '../AlmostDonePage.style';
import {
  LATE_LICENCE_RENEWAL_URL,
  SUSPENSION_DISQUALIFICATION_URL,
} from 'constants/constants';
import { ILicenceCategoryLicenceApi } from 'interfaces/api/response/ILicence';

interface LicenceAlertProps {
  licenceCategory: ILicenceCategoryLicenceApi[];
}

const ActiveLicenceAlert: React.FC<LicenceAlertProps> = ({
  licenceCategory,
}) => {
  const shouldDisplayAlert =
    licenceCategory &&
    (hasInactiveLicence(licenceCategory) ||
      hasAppealedSuspensionCancelExists(licenceCategory)) &&
    hasDualClass(licenceCategory);

  if (!shouldDisplayAlert) {
    return null;
  }

  return (
    <Spacer mt={2}>
      <InPageAlertHeaderOverride
        variant='info'
        title='Your new NSW Driver Licence card will only include your active class'
      >
        <p>
          For more information on how to reinstate your inactive class choose
          one of the following links:
        </p>
        <InWarningUnorderedWrapper>
          <InWarningList>
            <TextLinkBlue
              href={
                SUSPENSION_DISQUALIFICATION_URL +
                '#toc-disqualification-removal'
              }
              target='_blank'
              rel='noopener noreferrer'
            >
              Disqualified, cancelled or suspended
            </TextLinkBlue>
          </InWarningList>
          <InWarningList>
            <TextLinkBlue
              href={LATE_LICENCE_RENEWAL_URL}
              target='_blank'
              rel='noopener noreferrer'
            >
              Expired
            </TextLinkBlue>
          </InWarningList>
        </InWarningUnorderedWrapper>
      </InPageAlertHeaderOverride>
    </Spacer>
  );
};

export default ActiveLicenceAlert;
