import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { Config } from '../config/env';
import IValidateReplaceLicenceRequest from '../interfaces/api/request/IValidateReplaceLicenceRequest';
import {
  HttpRequestHandler,
  UserToken,
  ErrorService,
} from '@rmstransactions/components';
import { TRANSACTION_STATE, REPLACE_LICENCE_URL } from 'constants/constants';
import { IUserType } from 'interfaces/api/response/IUserType';
import { ICustomer } from 'interfaces/api/response/ICustomer';
import { ILicence } from 'interfaces/api/response/ILicence';
import { IEligibility } from 'interfaces/api/response/IEligibility';
import { IValidate } from 'interfaces/api/response/IValidate';
import { IDeclarationType } from 'interfaces/api/response/ISearchAndGetDeclaration';
import { IDeclarationForm } from 'interfaces/api/response/IDeclarationForm';
import { IPaymentResponse } from '../interfaces/api/response/IPaymentResponse';
import IPaymentRequest, {
  IEmailRequest,
} from '../interfaces/api/request/IPaymentRequest';
import { IRLConfirmationResponse } from '../interfaces/api/response/IRLConfirmationResponse';
import { IGetInterimDrivingLicencePDFResponse } from 'interfaces/api/response/IGetInterimDrivingLicence';
import { History } from 'history';

HttpRequestHandler.setCommonApiKeyHeader();

const {
  apiHost,
  apiValidateReplaceLicenceAuthPath,
  apiEligibilityReplaceLicenceAuthPath,
  apiGetLicenceDetailsAuthPath,
  apiGetCustomerDetailsAuthPath,
  apiUserType,
  apiSearchAndGetDeclarationsPath,
  apiGetDeclarationsFormPath,
  apiRequestPayment,
  apiGetInterimDrivingLicencePath,
  apiGetConfirmationAuthPath,
  apiGetConfirmationSupportPath,
  apiSupportSendEmail,
  apiAuthSendEmail,
} = Config;

const isMissingUserToken = (): boolean => {
  const userToken = UserToken.getValue();
  return userToken === null || userToken === 'null';
};

export const validateLicence = async (
  validateReplaceLicenceRequest: IValidateReplaceLicenceRequest
): Promise<AxiosResponse<IValidate>> => {
  const url = `${apiHost}${apiValidateReplaceLicenceAuthPath}`;
  return axios.post(url, validateReplaceLicenceRequest, {
    headers: { 'x-token': UserToken.getValue() },
    withCredentials: true,
  });
};

export const getEligibility = async (): Promise<
  AxiosResponse<IEligibility>
> => {
  const url = `${apiHost}${apiEligibilityReplaceLicenceAuthPath}`;
  return axios.get(url, {
    withCredentials: true,
    headers: { 'x-token': UserToken.getValue() },
  });
};

export const getLicenceDetailsByRmsCustomerId = async (): Promise<
  AxiosResponse<ILicence>
> => {
  const url = `${apiHost}${apiGetLicenceDetailsAuthPath}`;
  return axios.get(url, {
    headers: {
      'x-token': UserToken.getValue(),
    },
    withCredentials: true,
  });
};

export const getCustomerDetailsByRmsUserId = async (): Promise<
  AxiosResponse<ICustomer>
> => {
  const url = `${apiHost}${apiGetCustomerDetailsAuthPath}`;
  return axios.get(url, {
    headers: { 'x-token': UserToken.getValue() },
    withCredentials: true,
  });
};

export const getUserType = async (): Promise<AxiosResponse<IUserType>> => {
  const url = `${apiHost}${apiUserType}?state=${TRANSACTION_STATE}`;
  return axios.get(url, {
    headers: { 'x-token': UserToken.getValue() },
    withCredentials: true,
  });
};

export const searchAndGetDeclarations = async (): Promise<
  AxiosResponse<IDeclarationType>
> => {
  const url = `${apiHost}${apiSearchAndGetDeclarationsPath}`;
  return axios.get(url, {
    headers: { 'x-token': UserToken.getValue() },
    withCredentials: true,
  });
};

export const getDeclarationsForm = async (): Promise<
  AxiosResponse<IDeclarationForm>
> => {
  const url = `${apiHost}${apiGetDeclarationsFormPath}`;
  return axios.get(url, {
    headers: { 'x-token': UserToken.getValue() },
    withCredentials: true,
  });
};

export const replaceLicence = async (
  paymentRequest: IPaymentRequest
): Promise<AxiosResponse<IPaymentResponse>> => {
  const url = `${apiHost}${apiRequestPayment}`;
  return axios.post(url, paymentRequest, {
    headers: { 'x-token': UserToken.getValue() },
    withCredentials: true,
  });
};

export const getConfirmationData = async (
  uuid: string
): Promise<AxiosResponse<IRLConfirmationResponse>> => {
  const url = `${apiHost}${apiGetConfirmationAuthPath}/${uuid}`;
  const missingUserToken = isMissingUserToken();
  if (missingUserToken && uuid) {
    return axios.get(`${apiHost}${apiGetConfirmationSupportPath}/${uuid}`, {
      withCredentials: false,
    });
  }
  return axios.get(url, {
    headers: { 'x-token': UserToken.getValue() },
    withCredentials: true,
  });
};

export const getInterimDrivingLicence =
  async (): Promise<IGetInterimDrivingLicencePDFResponse> => {
    const url = `${apiHost}${apiGetInterimDrivingLicencePath}`;
    const resp = await axios.post(
      url,
      {},
      {
        headers: { 'x-token': UserToken.getValue() },
        withCredentials: true,
      }
    );
    return resp.data;
  };

export const sendEmail = async (
  emailRequest: IEmailRequest
): Promise<AxiosResponse<string>> => {
  const missingUserToken = isMissingUserToken();
  if (missingUserToken) {
    return axios.post(`${apiHost}${apiSupportSendEmail}`, emailRequest, {
      headers: { 'x-token': UserToken.getValue() },
      withCredentials: true,
    });
  }
  const url = `${apiHost}${apiAuthSendEmail}`;
  return axios.post(url, emailRequest, {
    headers: { 'x-token': UserToken.getValue() },
    withCredentials: true,
  });
};

export const forceLogout = async (): Promise<any> => {
  const res = await fetch(`${apiHost}/logout`, {
    credentials: 'include',
  });
  return res;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const interceptors = (history: History) => {
  HttpRequestHandler.setCommonContentType('application/json');

  axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    return config;
  });

  axios.interceptors.response.use(
    async (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      console.log('Inside Replace Licence Service ');
      ErrorService.handleAPIError(
        error,
        history,
        Config.apiHost,
        TRANSACTION_STATE,
        REPLACE_LICENCE_URL
      );
    }
  );
};
