export const Config: AppConfig = {
  apiHost: process.env.REACT_APP_API_HOST || '',
  globalNavEnv: process.env.REACT_APP_GLOBAL_NAV_ENV || '',
  logLevel: process.env.REACT_APP_LOG_LEVEL || '',
  apiValidateReplaceLicenceAuthPath: '/auth/repl-lic/validate',
  apiEligibilityReplaceLicenceAuthPath: '/auth/repl-lic/eligibility',
  apiGetLicenceDetailsAuthPath: '/auth/repl-lic/licence',
  apiGetCustomerDetailsAuthPath: '/auth/repl-lic/customer',
  apiSilentLoginPath: '/silentLogin',
  apiLoginPath: '/login',
  apiUserType: '/anon/userType',
  apiSearchAndGetDeclarationsPath: '/auth/repl-lic/searchAndGetDeclarations',
  apiGetDeclarationsFormPath: '/auth/repl-lic/declarationsForm',
  apiRequestPayment: '/auth/repl-lic/replace-licence',
  apiGetConfirmationAuthPath: '/auth/repl-lic/confirmation',
  apiGetConfirmationSupportPath: '/support/repl-lic/confirmation',
  apiGetInterimDrivingLicencePath: '/auth/repl-lic/getInterimLicencePDF',
  apiAuthSendEmail: '/auth/repl-lic/send-email',
  apiSupportSendEmail: '/support/repl-lic/send-email',
  emailUpdateUrl: process.env.REACT_APP_EMAIL_UPDATE_URL,
  addressUpdateUrl: process.env.REACT_APP_ADDRESS_UPDATE_URL,
};

export interface AppConfig {
  apiHost: string;
  globalNavEnv: string;
  logLevel: string;
  apiValidateReplaceLicenceAuthPath: string;
  apiEligibilityReplaceLicenceAuthPath: string;
  apiGetLicenceDetailsAuthPath: string;
  apiGetCustomerDetailsAuthPath: string;
  apiSilentLoginPath: string;
  apiLoginPath: string;
  apiUserType: string;
  apiSearchAndGetDeclarationsPath: string;
  apiGetDeclarationsFormPath: string;
  apiRequestPayment: string;
  apiGetConfirmationAuthPath: string;
  apiGetConfirmationSupportPath: string;
  apiGetInterimDrivingLicencePath: string;
  apiAuthSendEmail: string;
  apiSupportSendEmail: string;
  emailUpdateUrl: string;
  addressUpdateUrl: string;
}
