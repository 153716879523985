import styled from 'styled-components';
import { InPageAlert } from '@snsw/react-component-library';

export const OverflowTextInPageAlert = styled(InPageAlert)`
  > div {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: none;
  }
`;
