const sessionStorageManager = {
  getValue: (sessionStorageKey: string): string | null => {
    return sessionStorage.getItem(sessionStorageKey);
  },
  setValue: (sessionStorageKey: string, uuid: string): void => {
    sessionStorage.setItem(sessionStorageKey, uuid);
  },
  clear: (sessionStorageKey: string): void => {
    sessionStorage.removeItem(sessionStorageKey);
  },
  clearAll: (): void => {
    sessionStorage.clear();
  },
};

export default sessionStorageManager;
