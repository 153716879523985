import React from 'react';
import { useLocation } from 'react-router-dom';
import { StatusLabel } from '@snsw/react-component-library';
import { getDisplayLicenceStatus } from 'utils/helpers/helpers';

export interface LicenceBadgeProps {
  displayStatus: string;
  showTheBadge: boolean;
  badgeIndex: number;
  isExpired: boolean;
}

const LicenceBadge: React.FC<LicenceBadgeProps> = ({
  displayStatus,
  showTheBadge,
  badgeIndex,
  isExpired,
}) => {
  const location = useLocation();
  const currentRoute = location.pathname;

  const getVariant = (displayStatus: string, isExpired: boolean) => {
    if (isExpired && displayStatus.toUpperCase() === 'ACTIVE')
      return currentRoute === '/declaration' ? 'neutral' : 'error';

    if (displayStatus.toUpperCase() === 'ACTIVE') {
      return 'success';
    }
    if (currentRoute === '/licence' || currentRoute === '/error') {
      return 'error';
    }
    return 'neutral';
  };

  return (
    <>
      {showTheBadge && displayStatus && (
        <StatusLabel
          text={getDisplayLicenceStatus(isExpired, displayStatus)}
          variant={getVariant(displayStatus, isExpired)}
          data-testid={`licence-error-status-label-${badgeIndex}`}
        />
      )}
    </>
  );
};

export default LicenceBadge;
