import React from 'react';
import {
  InPageAlert,
  ContentContainer,
  Col,
  Row,
  Button,
} from '@snsw/react-component-library';
import { useStoreSelector } from 'redux/store';
import { Header } from '@rmstransactions/components';
import { ButtonContainer } from './ErrorLayout.styled';
import { Spacer } from '@rmstransactions/components';
import UsefulInfo from './UsefulInfo';
import CustomerDetails from 'components/CustomerDetails/CustomerDetails';
import { ErrorCodes, IInfo } from 'utils/helpers/ErrorHandlers/helpers/helpers';
import { LicenceStatusDetails } from 'utils/helpers/ErrorHandlers/apiErrorCheck';
import { DeclarationAnswers } from 'pages/ReviewAndConfirmation/components/declarationAnswers';
import { gotoDrupal, logoutAndResetUserSession } from 'utils/helpers/helpers';

interface ErrorProps {
  pageHeading: string;
  errorHeading: string;
  showInfoArray: IInfo[];
  showInfoArrayValidate: IInfo[];
}

export const ErrorLayout: React.FC<ErrorProps> = ({
  pageHeading,
  errorHeading,
  showInfoArray,
  showInfoArrayValidate,
  children,
}) => {
  const {
    selectedAnswer1,
    selectedAnswer2,
    existingDeclaration,
    question1,
    question2,
    validationErrorCodes,
  } = useStoreSelector((state) => ({
    selectedAnswer1: state.reviewDeclaration.answer1,
    selectedAnswer2: state.reviewDeclaration.answer2,
    question1: state.declarationForm.question1,
    question2: state.declarationForm.question2,
    existingDeclaration: state.validate.existingDeclaration,
    validationErrorCodes: state.validate.validationErrorCodes,
  }));

  const handleClickQuitBtn = async () => {
    try {
      await logoutAndResetUserSession();
    } catch (error) {
      // Handle error if needed
    } finally {
      gotoDrupal();
    }
  };

  const handleClickFindBtn = () => {
    window.open('https://www.service.nsw.gov.au/service-centre');
  };

  const isAttendServiceCenterError =
    selectedAnswer1?.toLowerCase() === 'yes' ||
    selectedAnswer2?.toLowerCase() === 'yes' ||
    validationErrorCodes?.includes('ATTEND_SERVICE_CENTRE');

  const errorPageHeading = isAttendServiceCenterError
    ? LicenceStatusDetails[ErrorCodes.ATTEND_SERVICE_CENTRE].pageHeading
    : pageHeading;
  const errorAlertHeading = isAttendServiceCenterError
    ? LicenceStatusDetails[ErrorCodes.ATTEND_SERVICE_CENTRE]?.title
    : errorHeading;
  const errorAlertDescription = isAttendServiceCenterError
    ? LicenceStatusDetails[ErrorCodes.ATTEND_SERVICE_CENTRE]?.description
    : children;
  const infoArray =
    showInfoArrayValidate?.length > 0 ? showInfoArrayValidate : showInfoArray;
  const usefullInfoArray = isAttendServiceCenterError
    ? LicenceStatusDetails[ErrorCodes.ATTEND_SERVICE_CENTRE]?.info
    : infoArray;

  const InfoArrayExist =
    (showInfoArray && showInfoArray.length > 0) ||
    (showInfoArrayValidate && showInfoArrayValidate.length > 0);

  return (
    <>
      <Header title={errorPageHeading} data-testid='header' />
      <ContentContainer>
        <Row>
          <Col span={8}>
            <InPageAlert variant='error' title={errorAlertHeading}>
              {errorAlertDescription}
            </InPageAlert>
            {isAttendServiceCenterError && (
              <DeclarationAnswers
                existingDeclaration={existingDeclaration}
                question1={question1}
                question2={question2}
                selectedAnswer1={selectedAnswer1}
                selectedAnswer2={selectedAnswer2}
                isAttendServiceCenterError={isAttendServiceCenterError}
              />
            )}
            {InfoArrayExist && (
              <>
                <Spacer mt={2.5}>
                  <CustomerDetails showLicenceCategory />
                </Spacer>
              </>
            )}
            {(InfoArrayExist || isAttendServiceCenterError) && (
              <UsefulInfo
                usefullInfoArray={usefullInfoArray}
                isAttendServiceCenterError={isAttendServiceCenterError}
              />
            )}
            <ButtonContainer>
              {(InfoArrayExist || isAttendServiceCenterError) && (
                <Button
                  onClick={handleClickFindBtn}
                  className='buttons'
                  data-testid='Find-a-service-centre-button'
                >
                  Find a service centre
                </Button>
              )}
              <Button
                onClick={handleClickQuitBtn}
                theme='secondary'
                className='buttons'
              >
                Quit
              </Button>
            </ButtonContainer>
          </Col>
        </Row>
      </ContentContainer>
    </>
  );
};

export default ErrorLayout;
