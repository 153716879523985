import React, { useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import {
  RMSGlobalStyle,
  MyAccountNav,
  MyAccountNavConfig,
  UserSession,
} from '@rmstransactions/components';
import { GlobalNav } from '@snsw/react-global-nav';
import { theme, GlobalStyle } from '@snsw/react-component-library';
import { ThemeProvider } from 'styled-components';
import { Config } from './config/env';
import { Provider } from 'react-redux';
import store from './redux/store';
import YourLicence from './pages/YourLicence/YourLicence';
import { DeclarationPage } from 'pages/DeclarationPage/DeclarationPage';
import LandingPage from 'pages/LandingPage/LandingPage';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import ReviewAndConfirmPage from 'pages/ReviewAndConfirmation/ReviewAndConfirmPage';
import AlmostDonePage from 'pages/AlmostDonePage/AlmostDonePage';
import AlmostDoneRedirect from 'pages/AlmostDoneRedirect/AlmostDoneRedirect';
import GoogleAnalyticsMonitor from 'components/GoogleAnalyticsMonitor/GoogleAnalyticsMonitor';
import { clickHandlerToLogoutLink } from 'utils/helpers/helpers';
import LoggedOutUserErrorPage from 'pages/ErrorPage/LoggedOutUserErrorPage';
import { createBrowserHistory } from 'history';
import { logoutUrl } from '@rmstransactions/components/src/MyAccountNav/MyAccountNav';
import TimeoutHandler from 'components/TimeoutHandler/TimeoutHandler';
import { RouteWrapper } from './utils/helpers/RouteWrapper';

const { globalNavEnv } = Config;
MyAccountNavConfig.setupGlobalNavUri(globalNavEnv);
MyAccountNavConfig.setLoggedInUser();
const history = createBrowserHistory();

const App: React.FC = () => {
  useEffect(() => {
    const handleClick = (event) => {
      // Get the element that was clicked
      const clickedElement = event.target;
      // Check if the clicked element is the excluded link
      if (
        clickedElement.tagName === 'A' &&
        clickedElement.getAttribute('href') === logoutUrl
      ) {
        // This is the excluded link, do nothing or handle it as needed
        return;
      }
      if (UserSession.isLoggedOutUser()) {
        history.push('/error/logged-out');
      }
    };

    // Add the click event listener to the document
    document.addEventListener('click', handleClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [history]);

  useEffect(() => {
    clickHandlerToLogoutLink();
  }, []);
  return (
    <>
      <RMSGlobalStyle />
      <GlobalNav accountNavLinks={<MyAccountNav />}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Provider store={store}>
            <Router history={history}>
              <GoogleAnalyticsMonitor />
              <TimeoutHandler />
              <Switch>
                <Route path={['/', '/repl-lic']} exact>
                  <LandingPage />
                </Route>

                <RouteWrapper path='/licence' WrappedComponent={YourLicence} />
                <RouteWrapper
                  path='/declaration'
                  WrappedComponent={DeclarationPage}
                />

                <RouteWrapper
                  path='/view-receipt/:receiptUUID'
                  WrappedComponent={AlmostDonePage}
                />

                <RouteWrapper
                  path='/almost-done/:receiptUUID'
                  WrappedComponent={AlmostDoneRedirect}
                />

                <RouteWrapper
                  path='/almost-done'
                  WrappedComponent={AlmostDonePage}
                />

                <RouteWrapper
                  path={`/error/logged-out`}
                  exact
                  WrappedComponent={LoggedOutUserErrorPage}
                />

                <RouteWrapper
                  path={['/error', '/failure/:errorCode']}
                  WrappedComponent={ErrorPage}
                />

                <RouteWrapper
                  path='/review-and-confirm'
                  WrappedComponent={ReviewAndConfirmPage}
                />
              </Switch>
            </Router>
          </Provider>
        </ThemeProvider>
      </GlobalNav>
    </>
  );
};

export default App;
