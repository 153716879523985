import { Modal } from '@snsw/react-component-library';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { sendEmail } from '../../../services/ReplaceLicenceService';
import { useParams } from 'react-router-dom';
import { RoutePathParams } from '../../../utils/helpers/helpers';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay';
import { ResponseType } from '../AlmostDonePage';
import { ResponseTypeValue } from '../../../interfaces/api/request/IPaymentRequest';

const ConfirmEmailModal: React.FC<EmailProps> = ({
  emailValue,
  setShowModal,
  setEmailPostStatus,
}) => {
  const { receiptUUID } = useParams<RoutePathParams>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleSend = async (emailValue: string) => {
    setShowModal(false);
    setEmailPostStatus(null);
    setIsLoading(true);
    try {
      const response = await sendEmail({
        emailAddress: emailValue,
        uuid: receiptUUID,
      });
      if (!response.data || response.data === '') {
        setEmailPostStatus(ResponseTypeValue.ERROR);
      } else {
        setEmailPostStatus(ResponseTypeValue.SUCCESS);
      }
    } catch (error) {
      setEmailPostStatus(ResponseTypeValue.ERROR);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Modal
        title='Is this email address correct?'
        buttons={[
          { text: 'Send', onClick: () => handleSend(emailValue) },
          { text: 'Cancel', onClick: () => setShowModal(false) },
        ]}
      >
        <p>
          {`For your security, please confirm you'd like your Interim Driver
        Licence (Driver Licence receipt) sent to `}
          <strong>{emailValue}</strong>
        </p>
      </Modal>
      <LoadingOverlay visible={isLoading} />
    </>
  );
};

export default ConfirmEmailModal;

interface EmailProps {
  emailValue: string;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setEmailPostStatus: Dispatch<SetStateAction<null | ResponseType>>;
}
