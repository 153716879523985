import React from 'react';
import {
  Accordion,
  AccordionItem,
} from '@snsw/react-component-library/build/Components';
import { HorizontalRule } from '@snsw/react-component-library';
import * as Styled from './ErrorLayout.styled';
import { Spacer } from '@rmstransactions/components';
import parser from 'html-react-parser';
import { IInfo } from 'utils/helpers/ErrorHandlers/helpers/helpers';

interface ShowInfoArrayType {
  usefullInfoArray: IInfo[];
  isAttendServiceCenterError: boolean;
}

const UsefulInfo: React.FC<ShowInfoArrayType> = ({
  usefullInfoArray,
  isAttendServiceCenterError,
}) => {
  const heading = isAttendServiceCenterError
    ? 'Some useful information'
    : 'Useful information';
  return (
    <>
      {isAttendServiceCenterError ? (
        <Spacer mt={2} pb={2} />
      ) : (
        <HorizontalRule marginTop='2.5rem' marginBottom='2.5rem' />
      )}
      <Styled.HeadingOverrideStyle level={2}>
        {heading}
      </Styled.HeadingOverrideStyle>
      <Spacer mb={3.5}>
        <Accordion id='usefulInfo'>
          {usefullInfoArray?.map((info) => {
            return (
              <AccordionItem key={info.title} title={info.title}>
                <div>{parser(info.description)}</div>
              </AccordionItem>
            );
          })}
        </Accordion>
      </Spacer>
      <Styled.HeadingOverrideStyle level={3}>
        Find a Service NSW service centre
      </Styled.HeadingOverrideStyle>
      <p>
        Search by suburb, postcode or current location to find a service centre
        near you.
      </p>
    </>
  );
};

export default UsefulInfo;
