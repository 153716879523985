import React from 'react';
import { Heading, InPageAlert } from '@snsw/react-component-library';
import { Spacer } from '@rmstransactions/components';
import { NeedHelpButton } from 'components/NeedHelpButton/NeedHelpButton';
import { LicenceStatusDetails } from 'utils/helpers/ErrorHandlers/apiErrorCheck';
import { ErrorCodes } from 'utils/helpers/ErrorHandlers/helpers/helpers';
import { QuestionCardContainer } from '../style/ReviewAndConfirmPage.styled';

interface DeclarationProps {
  existingDeclaration: string;
  question1: string;
  question2: string;
  selectedAnswer1: string;
  selectedAnswer2: string;
  isAttendServiceCenterError?: boolean;
}

export const DeclarationAnswers: React.FC<DeclarationProps> = ({
  existingDeclaration,
  question1,
  question2,
  selectedAnswer1,
  selectedAnswer2,
  isAttendServiceCenterError,
}) => {
  return (
    <>
      <Heading level={2}>Your declaration answers</Heading>
      {!isAttendServiceCenterError &&
        (existingDeclaration === 'Y' ? (
          <InPageAlert
            variant='info'
            title={LicenceStatusDetails[ErrorCodes.DECL_ALREADY_EXISTS]?.title}
          >
            <p>
              {
                LicenceStatusDetails[ErrorCodes.DECL_ALREADY_EXISTS]
                  ?.description
              }
            </p>
          </InPageAlert>
        ) : (
          <InPageAlert
            variant='info'
            title='Review your declaration carefully before you submit it'
          >
            <p>
              Answer the questions truthfully. It is an offence to provide false
              or dishonest information.
            </p>
          </InPageAlert>
        ))}

      <Spacer mt={2} mb={1.5}>
        <QuestionCardContainer>
          <Heading level={4}>Question 1</Heading>
          <p>{question1}</p>
          <b>Your answer: {selectedAnswer1}</b>
          {!isAttendServiceCenterError && <NeedHelpButton index={1} />}
        </QuestionCardContainer>
      </Spacer>
      <QuestionCardContainer>
        <Heading level={4}>Question 2</Heading>
        <p>{question2}</p>
        <b>Your answer: {selectedAnswer2}</b>
        {!isAttendServiceCenterError && <NeedHelpButton index={2} />}
      </QuestionCardContainer>
    </>
  );
};

export default DeclarationAnswers;
