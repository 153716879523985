import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IValidationRequestResponseDetailsPriceDetails } from 'interfaces/api/response/IValidate';
import { ISystemMessages } from 'interfaces/api/response/IMessage';
import { IInfo } from 'utils/helpers/ErrorHandlers/helpers/helpers';

interface IDeclarationDetails {
  existingDeclaration: string | null;
  declarationId: string | null;
}
export interface ValidateState {
  status: string | null;
  pensionerDiscountFlag: string | null;
  deferredPensionerCheck: string | null;
  priceDetails: IValidationRequestResponseDetailsPriceDetails | null;
  replacementReason: string | null;
  systemMessages: ISystemMessages | null;
  validationErrorCodes: string[] | null;
  isErrorInValidateResponse: boolean;
  validateWarningCodes: string[] | null;
  isWarningInValidateCall: boolean;
  existingDeclaration: string | null;
  declarationId: string | null;
  showInfoArray: IInfo[] | null;
}

export const initialState: ValidateState = {
  status: null,
  pensionerDiscountFlag: null,
  deferredPensionerCheck: null,
  priceDetails: null,
  replacementReason: null,
  systemMessages: null,
  validationErrorCodes: null,
  isErrorInValidateResponse: false,
  validateWarningCodes: null,
  isWarningInValidateCall: null,
  existingDeclaration: null,
  declarationId: null,
  showInfoArray: null,
};

export const validateSlice = createSlice({
  name: 'validate',
  initialState,
  reducers: {
    setPensionerDiscountFlag: (state, action: PayloadAction<string>) => {
      state.pensionerDiscountFlag = action.payload;
      state.status = 'success';
    },
    setDeferredPensionerCheck: (state, action: PayloadAction<string>) => {
      state.deferredPensionerCheck = action.payload;
      state.status = 'success';
    },
    setPriceDetails: (
      state,
      action: PayloadAction<IValidationRequestResponseDetailsPriceDetails>
    ) => {
      state.priceDetails = action.payload;
      state.status = 'success';
    },

    setReplacementReason: (state, action: PayloadAction<string>) => {
      state.replacementReason = action.payload;
    },

    setSystemMessagesWithBusinessError: (
      state,
      action: PayloadAction<{
        systemMessages: ISystemMessages;
        validationErrorCodes: string[];
        isErrorInValidateResponse: boolean;
        validateWarningCodes: string[];
        isWarningInValidateCall: boolean;
        showInfoArray: IInfo[];
      }>
    ) => {
      const {
        systemMessages,
        validationErrorCodes,
        isErrorInValidateResponse,
        validateWarningCodes,
        isWarningInValidateCall,
        showInfoArray,
      } = action.payload;

      state.systemMessages = systemMessages;
      state.validationErrorCodes = validationErrorCodes;
      state.isErrorInValidateResponse = isErrorInValidateResponse;
      state.validateWarningCodes = validateWarningCodes;
      state.isWarningInValidateCall = isWarningInValidateCall;
      state.showInfoArray = showInfoArray;
    },
    setDeclarationDetails: (
      state,
      action: PayloadAction<IDeclarationDetails>
    ) => {
      const { existingDeclaration, declarationId } = action.payload;
      state.existingDeclaration = existingDeclaration;
      state.declarationId = declarationId;
    },
    reset: () => initialState,
  },
});

export const validateSliceActions = validateSlice.actions;
export const validateReducer = validateSlice.reducer;
