import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface interimLicence {
  licencePDF: string | null;
  errorCodes: string[] | null,
  warningCodes: string[] | null
}

export const initialState: interimLicence = {
  licencePDF: null,
  errorCodes: null,
  warningCodes: null
};

export const interimLicenceSlice = createSlice({
  name: 'interimLicence',
  initialState,
  reducers: {

    setSystemMessagesWithBusinessError: (
      state,
      action: PayloadAction<{
        errorCodes: string[];
        warningCodes: string[];
      }>
    ) => {
      const { errorCodes, warningCodes } =
        action.payload;
      state.errorCodes = errorCodes;
      state.warningCodes = warningCodes;
    },
    resetLicenceDetails: () => initialState,
  },
});

export const interimLicenceSliceActions = interimLicenceSlice.actions;
export const interimLicenceReducer = interimLicenceSlice.reducer;
