import React, { useState, useEffect, useRef } from 'react';
import { useStoreDispatch, useStoreSelector } from 'redux/store';
import { useHistory } from 'react-router-dom';
import {
  ProgressStepper,
  KioskService,
  DeviceUtils,
} from '@rmstransactions/components';
import {
  HorizontalRule,
  Button,
  ContentContainer,
  FormCheckbox,
  SectionPage,
  BrandIconLocked,
  Modal,
} from '@snsw/react-component-library';
import { Col, Row } from '@rmstransactions/components/Styled';
import { stepsList } from '../../utils/progressBar/stepsList';
import CustomerDetails from '../../components/CustomerDetails/CustomerDetails';
import { Spacer } from '@rmstransactions/components';
import { HelpLink } from '../../components/NeedHelpButton/NeedHelpButton.style';
import { ButtonGroup } from '../YourLicence/YourLicence.styled';
import TermsAndConditionModalContent from './components/TermsAndConditionModalContent';
import { FeesBreakdown } from 'components/Fees/FeesBreakdown';
import {
  HeadingOverride,
  PrivacyWrapper,
  TitleText,
} from './style/ReviewAndConfirmPage.styled';
import { handleValidateDetails } from '../YourLicence/YourLicenceHelper';
import {
  IDeclarationDetails,
  Channel,
  ExistingDeclaration,
  IDeclarationResponse,
  IDeclarationResponses,
  IQuestion,
  IResponse,
} from '../../interfaces/api/request/IDeclarationDetails';
import IPaymentRequest from '../../interfaces/api/request/IPaymentRequest';
import {
  interceptors,
  replaceLicence,
} from '../../services/ReplaceLicenceService';
import NotifyCustomer from 'pages/YourLicence/components/NotifyCustomer';
import { LicenceStatusDetails } from 'utils/helpers/ErrorHandlers/apiErrorCheck';
import {
  consessionCanNotValidateTitle,
  pensionerValidationDeferredDescription,
} from 'utils/helpers/ErrorHandlers/helpers/errorContentDetails';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import { DeclarationAnswers } from './components/declarationAnswers';
import { validateSliceActions } from 'redux/slice/validate';
import {
  handleScrollToElement,
  handleScrollToTop,
  invokeSilentLoginIfSessionNotActive,
} from 'utils/helpers/helpers';
import { eligibilitySliceActions } from 'redux/slice/eligibility';
import {
  ActiveRefusalSanctionModalContent
} from './components/ActiveRefusalSanctionModalContent';
import {
  ActiveRefusalSanctionModalContentForPensioner
} from './components/ActiveRefusalSanctionModalContent';


const ReviewAndConfirmPage: React.FC = () => {
  const dispatch = useStoreDispatch();
  const {
    selectedAnswer1,
    selectedAnswer2,
    question1,
    question2,
    replacementReason,
    concessionCardNumber,
    givenName,
    concessionType,
    replacementFee,
    validateWarningCodes,
    validationErrorCodes,
    pensionerDiscountFlag,
    deferredPensionerCheck,
    existingDeclaration: currentDeclaration,
  } = useStoreSelector((state) => ({
    selectedAnswer1: state.reviewDeclaration.answer1,
    selectedAnswer2: state.reviewDeclaration.answer2,
    question1: state.declarationForm.question1,
    question2: state.declarationForm.question2,
    replacementReason: state.validate.replacementReason,
    concessionCardNumber: state.concessionCard.concessionCardNumber,
    givenName: state.customer.givenName,
    concessionType: state.concessionCard.concessionType,
    replacementFee: state.validate?.priceDetails?.priceDetails?.netAmount,
    isErrorInValidateResponse: state.validate.isErrorInValidateResponse,
    validateWarningCodes: state.validate.validateWarningCodes,
    validationErrorCodes: state.validate.validationErrorCodes,
    pensionerDiscountFlag: state.validate.pensionerDiscountFlag,
    deferredPensionerCheck: state.validate.deferredPensionerCheck,
    existingDeclaration: state.validate.existingDeclaration,
  }));

  const [
    showIsActiveRefusalSanctionWarning,
    setShowIsActiveRefusalSanctionWarning,
  ] = useState<boolean>(false);
  let doesWarningExists = false;
  let existingDeclaration = currentDeclaration;
  const [isChecked, setCheckedState] = useState<boolean>(null);
  const [toggleTAndCModal, setToggleTAndCModal] = useState<boolean>(false);
  const [submitDeclarationsSelected, setSubmitDeclarationsSelected] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const refToTAndC = useRef<HTMLDivElement | null>(null);

  const showIsValidCardWithDeferredCheckWarning =
    pensionerDiscountFlag === 'N' &&
    (deferredPensionerCheck === 'Y' ||
      validateWarningCodes.includes(
        'PENSIONER_CHECK_DEFERRED_ON_PENSIONER_VALIDATION'
      ));

  const history = useHistory();

  const onChangeFormCheckBox = () => {
    setCheckedState(!isChecked);
  };

  const handleContinueToPaymentOrFulfilment = async () => {
    if (!isChecked) {
      setCheckedState(false);
      return;
    }
    setIsLoading(true);
    await handleReplaceLicence();

    // This is to avoid the glitch when the call is finished but
    // page is still not redirected to the redirection set
    // and the page becomes active again for a moment
    // the timeout will ensure it is called much later,
    // possibly not called if redirection already successful
    // However, in case of any other error it will unblock the
    // User interaction
    setTimeout(() => setIsLoading(false), 3000);
  };

  const isFullDiscounted = () => {
    return parseInt(replacementFee) === 0;
  };

  const paymentRequest: IPaymentRequest = {
    kioskId: KioskService.isKiosk() ? KioskService.getKioskId() : null,
  };

  const handleSubmitDeclarations = async () => {
    if (!isChecked) {
      setCheckedState(false);
      handleScrollToElement(refToTAndC.current);
      return;
    }
    setSubmitDeclarationsSelected(true);
    const question_1: IQuestion = {
      displayOrder: 1,
      displaySequence: 1,
      questionId: 16,
      questionText: question1,
    };
    const question_2: IQuestion = {
      displayOrder: 2,
      displaySequence: 2,
      questionId: 17,
      questionText: question2,
    };
    const response1: IResponse = {
      answer: [selectedAnswer1],
      answerTitle: 'RefusedDrivingFlag',
    };
    const response2: IResponse = {
      answer: [selectedAnswer2],
      answerTitle: 'DisqualifiedLicenceFlag',
    };
    const declarationResponse1: IDeclarationResponse = {
      question: question_1,
      response: response1,
    };
    const declarationResponse2: IDeclarationResponse = {
      question: question_2,
      response: response2,
    };
    const declarationResponses: IDeclarationResponses = {
      declaration: [declarationResponse1, declarationResponse2],
    };
    const declarationDetails: IDeclarationDetails = {
      channel: KioskService.isKiosk()
        ? Channel.UNASSISTED
        : Channel.SELF_SERVICE,
      declarationID: null,
      declarationResponses:
        existingDeclaration === 'Y' ? null : declarationResponses,
      deviceUsed: DeviceUtils.deviceUsed().toUpperCase(),
      existingDeclaration:
        existingDeclaration === 'Y'
          ? ExistingDeclaration.Y
          : ExistingDeclaration.N,
    };
    setIsLoading(true);
    const {
      systemMessages,
      validateErrorCodes,
      isErrorInValidateResponse,
      validateWarningCodes,
      showInfoArray,
      declarationId,
    } = await handleValidateDetails(
      replacementReason,
      concessionCardNumber,
      givenName,
      concessionType,
      true,
      declarationDetails
    );

    existingDeclaration = declarationId
      ? ExistingDeclaration.Y
      : ExistingDeclaration.N;

    dispatch(
      validateSliceActions.setDeclarationDetails({
        existingDeclaration,
        declarationId,
      })
    );

    dispatch(
      validateSliceActions.setSystemMessagesWithBusinessError({
        systemMessages,
        validationErrorCodes: validateErrorCodes,
        isErrorInValidateResponse,
        validateWarningCodes,
        isWarningInValidateCall: validateWarningCodes.length > 0,
        showInfoArray: showInfoArray,
      })
    );

    if (!isErrorInValidateResponse) {
      // Declaration successfully submitted, clear the state so that cancellation
      // or anything else will start a new validation
      dispatch(eligibilitySliceActions.reset());

      // Continue to payment
      setTimeout(() => {
        handleReplaceLicence();
      }, 500);
    }
  };

  const declarationSubmitButton = () => {
    const hasExistingDeclaration = existingDeclaration === 'Y';
    let onClick = handleContinueToPaymentOrFulfilment;
    let label = 'Continue to payment';

    if (!hasExistingDeclaration) {
      onClick = handleSubmitDeclarations;
      label = 'Yes, submit this declaration';
    } else if (isFullDiscounted()) {
      label = 'Continue';
    }

    return { onClick, label };
  };

  const callSubmitDeclaration = declarationSubmitButton();

  const handleCancelButton = () => {
    setShowIsActiveRefusalSanctionWarning(false);
    setIsLoading(false);
  };

  const handleModalClose = () => {
    setShowIsActiveRefusalSanctionWarning(false);
    setIsLoading(false);
  };

  const handleReplaceLicence = async () => {
    if (
      validateWarningCodes.includes('ACTIVE_REFUSAL_PRESENT') ||
      validateWarningCodes.includes('ACTIVE_SANCTION_EXISTS')
    ) {
      setShowIsActiveRefusalSanctionWarning(true);
      doesWarningExists = true;
    }
    if (!doesWarningExists) {
      invokeReplaceLicence();
    }
  };

  const invokeReplaceLicence = async () => {
    const paymentResponse = await replaceLicence(paymentRequest);
    window.location.href = paymentResponse.data.redirectUrl;
  };

  const acceptTAndCs = () => {
    setToggleTAndCModal(false);
    setCheckedState(true);
  };

  const hideTAndCs = () => {
    setToggleTAndCModal(false);
  };

  const handleBackButton = () => {
    history.push('/licence');
  };

  const handleMakeChanges = () => {
    history.push('/declaration');
  };

  useEffect(() => {
    //Invokes access error page when logged out in multi-tab
    interceptors(history);
    //On refresh should take you to licence page
    if (givenName === null) {
      history.push('/licence');
    }
    KioskService.sendMessage();
  }, []);

  useEffect(() => {
    if (submitDeclarationsSelected) {
      if (
        selectedAnswer1 === 'Yes' ||
        selectedAnswer2 === 'Yes' ||
        (validationErrorCodes &&
          validationErrorCodes.includes('ATTEND_SERVICE_CENTRE'))
      ) {
        history.push('/error');
      }
    }
    handleScrollToTop();
  }, [
    submitDeclarationsSelected,
    selectedAnswer1,
    selectedAnswer2,
    validationErrorCodes,
    history,
  ]);

  useEffect(() => {
    invokeSilentLoginIfSessionNotActive();
  }, [existingDeclaration]);

  const buttonText = (): string => {
    return isFullDiscounted()? 'Continue':'Continue to payment'
  };

  return (
    <>
      {' '}
      <ProgressStepper
        stepsList={stepsList[2]}
        label='Replace Driver Licence'
        title='Review and confirm'
      />
      <ContentContainer>
        <SectionPage>
          <Row>
            <Col lg={8}>
              {showIsValidCardWithDeferredCheckWarning && (
                <Spacer mt='-1rem' mb='2.5rem'>
                  <NotifyCustomer
                    title={
                      LicenceStatusDetails[validateWarningCodes[0]]?.title ||
                      consessionCanNotValidateTitle
                    }
                    details={
                      LicenceStatusDetails[validateWarningCodes[0]]
                        ?.description || pensionerValidationDeferredDescription
                    }
                  />
                </Spacer>
              )}
              <CustomerDetails showLicenceCategory />
              <HorizontalRule marginTop='2.5rem' marginBottom='2.5rem' />
              <HeadingOverride level={2}>Replacement details</HeadingOverride>
              <FeesBreakdown />
              <HorizontalRule marginTop='3.5rem' marginBottom='3.5rem' />
              <DeclarationAnswers
                existingDeclaration={existingDeclaration}
                question1={question1}
                question2={question2}
                selectedAnswer1={selectedAnswer1}
                selectedAnswer2={selectedAnswer2}
              />
              <Spacer mt={3.5}>
                <span ref={refToTAndC}>
                  Please read and accept the
                  <HelpLink
                    href=''
                    onClick={(e) => {
                      e.preventDefault();
                      setToggleTAndCModal(true);
                    }}
                    data-testid='tAndC-button'
                  >
                    Terms and Conditions
                  </HelpLink>
                  before proceeding.
                </span>
              </Spacer>
              {toggleTAndCModal && (
                <Modal
                  title='Terms & Conditions: Replace Driver Licence'
                  isScrollable
                  onClose={hideTAndCs}
                  buttons={[
                    {
                      text: 'Accept',
                      id: 'acceptTAndCs',
                      onClick: acceptTAndCs,
                    },
                    { text: 'Close', id: 'closeTAndCs', onClick: hideTAndCs },
                  ]}
                >
                  <TermsAndConditionModalContent />
                </Modal>
              )}
              <FormCheckbox
                id='tnCCheckbox'
                name='FormCheckbox name'
                label='I accept the Terms and Conditions'
                value=''
                errorMessage='Please accept the Terms and Conditions to continue'
                hasError={isChecked === false}
                testId='tAndCCheckBox'
                data-testid='tnc'
                onChange={onChangeFormCheckBox}
                checked={isChecked}
              />

              <PrivacyWrapper>
                <Spacer mt='1.25rem' mr='1.25rem'>
                  <BrandIconLocked />
                </Spacer>

                <div>
                  <p>
                    Your access and personal information is recorded by Service
                    NSW so technical assistance can be provided to you. Your
                    details have been collected to be verified by Transport for
                    NSW to access your records. To learn how your personal
                    information is handled, see the{' '}
                    <a
                      className='fontColor'
                      href='https://www.service.nsw.gov.au/privacy'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Service NSW Privacy Statement
                    </a>{' '}
                    and the{' '}
                    <a
                      className='fontColor'
                      href='https://www.transport.nsw.gov.au/privacy-statement'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Transport for NSW Privacy Statement.
                    </a>
                  </p>
                </div>
              </PrivacyWrapper>

              <TitleText>
                <strong>You are about to make a legal declaration.</strong>
                <br />
                Are you ready to submit this declaration?
              </TitleText>
            </Col>
          </Row>
          <Spacer mt={3.5}>
            <ButtonGroup>
              <Button
                data-testid='submitDeclarationBtn'
                onClick={callSubmitDeclaration.onClick}
                className='button submitDeclarationBtn'
              >
                <b>{callSubmitDeclaration.label}</b>
              </Button>
              <Button
                id='noNeedChangeBtn'
                data-testid='noNeedChangeBtn'
                theme='secondary'
                className='button noNeedChangeBtn'
                onClick={
                  existingDeclaration === 'Y'
                    ? handleBackButton
                    : handleMakeChanges
                }
              >
                <b>
                  {existingDeclaration === 'Y'
                    ? 'Back'
                    : 'No, I need to make changes'}
                </b>
              </Button>
            </ButtonGroup>
          </Spacer>
          {showIsActiveRefusalSanctionWarning && (
            <Modal
              title='You are not eligible for a new Driver Licence Card'
              buttons={[
                {
                  text: buttonText(),
                  onClick: invokeReplaceLicence,
                },
                { text: 'Cancel', onClick: handleCancelButton },
              ]}
              onClose={handleModalClose}
            >
               {isFullDiscounted()? ActiveRefusalSanctionModalContentForPensioner:ActiveRefusalSanctionModalContent}
            </Modal>
          )}
        </SectionPage>
      </ContentContainer>
      <LoadingOverlay visible={isLoading} />
    </>
  );
};

export default ReviewAndConfirmPage;
