import React from 'react';
import { useStoreSelector } from 'redux/store';
import { capitalise } from 'utils/helpers/helpers';
import {
  FeesBreakdownContainer,
  FeesBreakdownRow,
} from './FeesBreakdown.style';

export const FeesBreakdown: React.FC = () => {
  const { priceDetails, replacementReason } = useStoreSelector((state) => ({
    priceDetails: state.validate.priceDetails,
    replacementReason: state.validate.replacementReason,
  }));

  const discountAmount = Number(priceDetails?.discountAmount);

  const formattedDiscountAmount = `${
    discountAmount > 0 ? '-' : ''
  }$${discountAmount.toFixed(2)}`;

  return (
    <>
      <FeesBreakdownContainer>
        <FeesBreakdownRow>
          <div>Replacement reason</div>
          <div data-testid='reason'>{capitalise(replacementReason)}</div>
        </FeesBreakdownRow>
        <FeesBreakdownRow>
          <div>Replacement fee</div>
          <div data-testid='fullFee'>
            ${Number(priceDetails?.fullFeeAmount).toFixed(2)}
          </div>
        </FeesBreakdownRow>
        <FeesBreakdownRow>
          <div>Pensioner discount</div>
          <div data-testid='discountAmount'>{formattedDiscountAmount}</div>
        </FeesBreakdownRow>
        <FeesBreakdownRow>
          <div>GST</div>
          <div>${Number(priceDetails?.priceDetails.gstAmount).toFixed(2)}</div>
        </FeesBreakdownRow>
        <FeesBreakdownRow>
          <strong>Total replacement cost</strong>
          <strong data-testid='netAmount'>
            ${Number(priceDetails?.priceDetails.netAmount).toFixed(2)}
          </strong>
        </FeesBreakdownRow>
      </FeesBreakdownContainer>
    </>
  );
};
