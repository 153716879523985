import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDriveMessageDetail } from '../../interfaces/api/response/IRLConfirmationResponse';
import { ILicenceCategoryLicenceApi } from 'interfaces/api/response/ILicence';

export interface IConfirmationDetails {
  emailAddress: string | null;
  mailingAddress: string | null;
  receiptNumber: string | null;
  firstName: string | null;
  transactionDate: string | null;
  amountPaid: number;
  interimLicencePDFBase64: string | null;
  licenceCategory: ILicenceCategoryLicenceApi[];
  receiptPDFBase64: string | null;
  messages: IDriveMessageDetail[] | null;
}

export const initialState: IConfirmationDetails = {
  emailAddress: null,
  mailingAddress: null,
  receiptNumber: null,
  firstName: null,
  transactionDate: null,
  amountPaid: 0,
  interimLicencePDFBase64: null,
  licenceCategory: null,
  receiptPDFBase64: null,
  messages: null,
};

export const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    setConfirmationDetails: (
      state,
      action: PayloadAction<{
        confirmationDetails: IConfirmationDetails;
      }>
    ) => {
      const confirmationDetails = action.payload.confirmationDetails;
      state.emailAddress = confirmationDetails.emailAddress;
      state.mailingAddress = confirmationDetails.mailingAddress;
      state.transactionDate = confirmationDetails.transactionDate;
      state.receiptNumber = confirmationDetails.receiptNumber;
      state.amountPaid = confirmationDetails.amountPaid;
      state.interimLicencePDFBase64 =
        confirmationDetails.interimLicencePDFBase64;
      state.receiptPDFBase64 = confirmationDetails.receiptPDFBase64;
      state.messages = confirmationDetails.messages;
      state.firstName = confirmationDetails.firstName;
      state.licenceCategory = confirmationDetails.licenceCategory;
    },
    resetConfirmationDetails: () => initialState,
  },
});

export const confirmationSliceActions = confirmationSlice.actions;
export const confirmationReducer = confirmationSlice.reducer;
