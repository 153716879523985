import { InPageAlert } from '@snsw/react-component-library';
import React from 'react';

interface notifyProps {
  title: string;
  details: JSX.Element;
}

const NotifyCustomer: React.FC<notifyProps> = ({ title, details }) => {
  return (
    <InPageAlert variant='warning' title={title}>
      {details}
    </InPageAlert>
  );
};

export default NotifyCustomer;
