import React from 'react';
import { useLocation } from 'react-router-dom';
import { useStoreSelector } from 'redux/store';
import { Spacer, Svg, Box } from '@rmstransactions/components';
import {
  CustomerDetailsWrapper,
  CustomerLicenceExpiry,
  VehicleInfoContainer,
} from './CustomerDetails.styled';
import { ILicenceCategoryLicenceApi } from 'interfaces/api/response/ILicence';
import { CustomerDetailsSkeleton } from './CustomerDetailsSkeleton';
import {
  getValidatedDate,
  hasActiveAndStatus,
  isExpired,
  licenceTypeList,
  sortByLicenceType,
} from 'utils/helpers/helpers';
import LicenceBadge from 'components/LicenceBadge/LicenceBadge';
import { SUSPENSION_DISQUALIFICATION_URL } from 'constants/constants';

const licenceClassList = {
  C: 'Car',
  R: 'Rider',
  LR: 'Light Rigid',
  MR: 'Medium Rigid',
  HR: 'Heavy Rigid',
  HC: 'Heavy Combination',
  MC: 'Multi Combination',
};

export interface ShowLicenceCategoryProps {
  showLicenceCategory: boolean;
}

const CustomerDetails: React.FC<ShowLicenceCategoryProps> = ({
  showLicenceCategory,
}): JSX.Element => {
  const {
    givenName,
    familyName,
    licenceCategory,
    getLicenceStatus,
    getCustomerStatus,
    licenceNumber,
    unstructuredName,
    eligibilityIdentifier,
    isError,
    licenceCategoryEligibility,
  } = useStoreSelector((state) => ({
    getLicenceStatus: state.licence.status,
    getCustomerStatus: state.customer.status,
    licenceCategory: state.licence.licenceCategory,
    licenceNumber: state.licence.licenceNumber,
    givenName: state.customer.givenName,
    familyName: state.customer.familyName,
    unstructuredName: state.customer.unstructuredName,
    eligibilityIdentifier: state.eligibility.eligibilityIdentifier,
    isError: state.eligibility.isError,
    licenceCategoryEligibility: state.eligibility.licenceCategoryEligibility,
  }));

  const location = useLocation();
  const currentRoute = location.pathname;

  const givenNameCamelCase =
    unstructuredName !== null && givenName !== null
      ? `${unstructuredName.substring(0, givenName.length)} `
      : '';

  const isReviewAndConfirmPage: boolean =
    currentRoute === '/review-and-confirm';
  const isLicencePage: boolean = currentRoute === '/licence';

  return (
    <CustomerDetailsWrapper>
      {getLicenceStatus && getCustomerStatus === 'success' ? (
        <>
          <Spacer mb='1.5rem'>
            <Box
              fontSize='1.5rem'
              fontWeight='bold'
              data-testid='YourLicenseName'
            >{`${givenNameCamelCase} ${familyName}`}</Box>
            <Box fontSize='1.5rem' data-testid='YourLicenseNumber'>
              Licence number: <b>{licenceNumber}</b>
            </Box>
          </Spacer>
          {showLicenceCategory && (
            <Spacer mb={1.5}>
              {sortByLicenceType(licenceCategory).map(
                (vehicle: ILicenceCategoryLicenceApi, i: number) => {
                  const classCode = vehicle.licenceClass.code;
                  const licenceTypeCode = vehicle.licenceType.code;
                  const licenceStatus = vehicle.licenceStatus.toLowerCase();
                  const isVehicleActive =
                    licenceStatus === 'active' &&
                    !isExpired(vehicle.expiryDate);

                  const validLicenceStatus = [
                    'suspended',
                    'cancelled',
                    'disqualified',
                  ];
                  const showLicenceInfo =
                    validLicenceStatus.includes(licenceStatus) &&
                    hasActiveAndStatus(licenceCategoryEligibility, 'ERROR') &&
                    licenceCategory.length > 1 &&
                    isLicencePage;

                  return (
                    <React.Fragment key={`vehicleDetails-${classCode}`}>
                      {isReviewAndConfirmPage && !isVehicleActive ? null : (
                        <VehicleInfoContainer data-testid='YourLicenseVehicleDetailsItem'>
                          <div>
                            {classCode === 'C' ? (
                              <Svg.NewCar width={46} height={38} />
                            ) : classCode === 'R' ? (
                              <Svg.MotorBike width={46} height={38} />
                            ) : (
                              <Svg.RigidVehicle width={46} height={40} />
                            )}
                          </div>
                          <div>
                            <Box
                              fontWeight='bold'
                              fontSize='1.375rem'
                              mb='0.7rem'
                              data-testid={`licenceClass-${i}`}
                            >
                              {classCode} - {licenceClassList[classCode]}
                            </Box>
                            <LicenceBadge
                              displayStatus={vehicle.licenceStatus}
                              showTheBadge={
                                !isReviewAndConfirmPage &&
                                (isError ||
                                  (eligibilityIdentifier?.length > 0 &&
                                    eligibilityIdentifier?.length <
                                      licenceCategory?.length) ||
                                  isExpired(vehicle.expiryDate))
                              }
                              badgeIndex={i}
                              isExpired={isExpired(vehicle.expiryDate)}
                            />
                            <Box mt={1}>
                              <strong>
                                {licenceTypeList[licenceTypeCode].code}
                              </strong>{' '}
                              ({licenceTypeList[licenceTypeCode].description})
                              <CustomerLicenceExpiry>
                                Expiry: {getValidatedDate(vehicle.expiryDate)}
                              </CustomerLicenceExpiry>
                            </Box>

                            {showLicenceInfo && (
                              <Spacer mt={1}>
                                <a
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  href={SUSPENSION_DISQUALIFICATION_URL}
                                  data-testid='licence-info-link'
                                >
                                  Information on {licenceStatus} licence class
                                </a>
                              </Spacer>
                            )}
                          </div>
                        </VehicleInfoContainer>
                      )}
                    </React.Fragment>
                  );
                }
              )}
            </Spacer>
          )}
        </>
      ) : (
        <CustomerDetailsSkeleton showLicenceCategory={showLicenceCategory} />
      )}
    </CustomerDetailsWrapper>
  );
};

export default CustomerDetails;
