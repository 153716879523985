import React from 'react';
import { InPageAlert } from '@snsw/react-component-library';
import { getDisplayLicenceStatus, isExpired } from 'utils/helpers/helpers';

interface notifyProps {
  inactiveStatus: string;
  expiryDate: string;
  showAlert: boolean;
}

export const NotifySingleReplacementOnly: React.FC<notifyProps> = ({
  inactiveStatus,
  expiryDate,
  showAlert,
}) => {
  const noticeTitle = 'This declaration is for your active class only';
  const noticeBody = showAlert && (
    <>
      You have a dual class licence and one class is{' '}
      {getDisplayLicenceStatus(
        isExpired(expiryDate),
        inactiveStatus
      ).toLowerCase()}
      . Only answer this declaration for your{' '}
      <strong>active licence class</strong>.
    </>
  );
  return (
    showAlert && (
      <>
        <InPageAlert variant='warning' title={noticeTitle}>
          {noticeBody}
        </InPageAlert>
      </>
    )
  );
};
