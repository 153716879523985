import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILicenceCategoryLicenceApi } from 'interfaces/api/response/ILicence';
import { IInfo } from 'utils/helpers/ErrorHandlers/helpers/helpers';

export interface eligibilityDetails {
  replacementReasons: string[];
  replaceLicenceEligibilityStatus: string | null;
  eligibilityIdentifier: string[] | null; // stores eligibilityErrorCode
  licenceStatus: string[] | null;
  isError: boolean;
  showInfoArray: IInfo[] | null;
  warningCodes: string[] | null;
  licenceCategoryEligibility: ILicenceCategoryLicenceApi[];
  status: string;
}

export const initialState: eligibilityDetails = {
  replacementReasons: ["Lost", "Stolen", "Destroyed"],
  replaceLicenceEligibilityStatus: null,
  eligibilityIdentifier: null,
  licenceStatus: null,
  isError: null,
  showInfoArray: null,
  warningCodes: null,
  licenceCategoryEligibility: null,
  status: null,
};

export const eligibilitySlice = createSlice({
  name: 'eligibility',
  initialState,
  reducers: {
    setEligibilityDetails: (state, action: PayloadAction<{ eligibilityStatus: string, replacementReasons: string[] }>) => {
      state.replaceLicenceEligibilityStatus = action.payload.eligibilityStatus;
      state.replacementReasons = action.payload.replacementReasons
      state.status = 'success';
    },
    setSystemMessagesWithBusinessError: (
      state,
      action: PayloadAction<{
        eligibilityIdentifier: string[];
        licenceStatus: string[];
        isError: boolean;
        showInfoArray: IInfo[];
        warningCodes: string[];
        licenceCategoryEligibility: ILicenceCategoryLicenceApi[];
      }>
    ) => {
      const {
        eligibilityIdentifier,
        licenceStatus,
        isError,
        showInfoArray,
        warningCodes,
        licenceCategoryEligibility,
      } = action.payload;

      state.eligibilityIdentifier = eligibilityIdentifier;
      state.licenceStatus = licenceStatus;
      state.isError = isError;
      state.showInfoArray = showInfoArray;
      state.warningCodes = warningCodes;
      state.licenceCategoryEligibility = licenceCategoryEligibility;
    },
    reset: () => initialState,
  },
});
export const eligibilitySliceActions = eligibilitySlice.actions;
export const eligibilityReducer = eligibilitySlice.reducer;
