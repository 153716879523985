import styled from 'styled-components';
import { Heading } from '@snsw/react-component-library';
import { Breakpoint } from '@rmstransactions/components/Styled';

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 3.5rem;
  flex-direction: column;
  ${Breakpoint('sm')`
    flex-direction: row;
    gap: 1.5rem;
 `}
`;

export const HeadingOverrideStyle = styled(Heading)`
  margin-top: 0;
  margin-bottom: 1rem;
`;
