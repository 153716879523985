import React from 'react';
import PropTypes from 'prop-types';
import SkeletonItemRectangle from './SkeletonItemRectangle';

/**
 * An animated skeleton loader,
 * Creates an svg element with child <rect> elements, using SkeletonItemRectangle component
 * unique id is required
 *
 * @component
 * @example
 * <SkeletonLoader id="loader1" width="273" height="136" title="Loading" description="">
 *   <SkeletonItemRectangle width="24" height="24" />
 *   <SkeletonItemRectangle width="24" height="24" y={56} />
 *   <SkeletonItemRectangle width="24" height="24" y={112} />
 *   <SkeletonItemRectangle width="239" height="16" x={33} y={4} />
 *   <SkeletonItemRectangle width="239" height="16" x={33} y={60} />
 *   <SkeletonItemRectangle width="239" height="16" x={33} y={116} />
 * </SkeletonLoader>
 *
 * @example
 * <SkeletonLoader id="loader2">
 */

const SkeletonLoader = ({
  width = '100%',
  height = '16',
  children,
  id,
  description,
  title = 'loading',
}) => {
  const labledBy =
    title || description
      ? `${title ? 'titleID' : ''} ${description ? 'descID' : ''}`
      : null;

  return (
    <svg
      data-testid={id}
      width={width}
      height={height}
      viewBox={`0 0 100 ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      preserveAspectRatio="none"
      aria-labelledby={labledBy}
    >
      {title && <title id="titleID">{title}</title>}
      {description && <desc id="descID">{description}</desc>}
      <mask id={`mask${id}`}>
        {children ? (
          children
        ) : (
          <SkeletonItemRectangle width={width} height={height} />
        )}
      </mask>

      <rect
        y="0"
        x="0"
        width={width}
        height={height}
        fill="url(#greyGrad)"
        mask={`url(#mask${id})`}
      />
      <defs>
        <linearGradient id="greyGrad" x1="-100%" x2="0%">
          <stop offset="0%" stopColor="#f4f7f9"></stop>
          <stop offset="50%" stopColor="#dee3e5"></stop>
          <stop offset="100%" stopColor="#f4f7f9"></stop>
          <animate
            attributeName="x1"
            dur="700ms"
            from="-100%"
            to="100%"
            repeatCount="indefinite"
          />
          <animate
            attributeName="x2"
            dur="700ms"
            from="0%"
            to="200%"
            repeatCount="indefinite"
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

SkeletonLoader.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default SkeletonLoader;
