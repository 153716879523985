import { getConfirmationData } from '../../services/ReplaceLicenceService';
import { IRLConfirmationResponse } from '../../interfaces/api/response/IRLConfirmationResponse';

export const handleConfirmationDetails = async (
  uuid: string
): Promise<{
  confirmationData: IRLConfirmationResponse;
}> => {
  try {
    const response = await getConfirmationData(uuid);
    const confirmationData = response.data;
    return {
      confirmationData,
    };
  } catch (error) {
    return;
  }
};
