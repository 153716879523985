import React from 'react';
import PropTypes from 'prop-types';

const SkeletonItemRectangle = ({
  width = '100%',
  height = '100%',
  x = 0,
  y = 0,
}) => (
  <rect
    width={width}
    height={height}
    x={x}
    y={y}
    fill="#fff"
    rx="2"
    data-testid="rect"
  />
);

SkeletonItemRectangle.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
};

export default SkeletonItemRectangle;
