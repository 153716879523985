import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  question1: string | null;
  question2: string | null;
}

export const initialState: UserState = {
  question1: null,
  question2: null,
};

export const declarationFormSlice = createSlice({
  name: 'declarationForm',
  initialState,
  reducers: {
    setQuestion1: (state, action: PayloadAction<string>) => {
      state.question1 = action.payload;
    },
    setQuestion2: (state, action: PayloadAction<string>) => {
      state.question2 = action.payload;
    },
    reset: () => initialState,
  },
});

export const declarationFormSliceActions = declarationFormSlice.actions;
export const declarationFormReducer = declarationFormSlice.reducer;
