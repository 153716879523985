import React from 'react';
import {
  Notification,
  IconNotificationError,
} from '@snsw/react-component-library';
import {
  Radio,
  RadioGroupFieldset,
  FormLegend,
  RadioCheckboxLegendLabel,
  InlineErrorStyle,
} from '../../Styled/FormRadioGroupStyleOverride';
import RadioItems from './Components/RadioItems';

const FormRadioGroupRms = ({
  id,
  name,
  className,
  legend,
  options,
  value,
  hasError,
  hasErrorInline,
  errorMessage,
  errorTitle,
  onChange,
  mt,
  mb,
  ...rest
}) => {
  const fieldClass = className === undefined ? '' : ` ${className}`;
  return (
    <RadioGroupFieldset className={fieldClass} aria-invalid={hasError}>
      {legend && (
        <FormLegend>
          <RadioCheckboxLegendLabel>{legend}</RadioCheckboxLegendLabel>
        </FormLegend>
      )}
      <Radio>
        {options &&
          options.map((option, index) => (
            <RadioItems
              key={index}
              id={id ? `${id}-${index}` : `radio-${index}`} // Concatenate with index
              option={option}
              value={value}
              onSelection={onChange}
              name={name}
              hasErrorInline={hasErrorInline}
              mt={mt}
              mb={mb}
            ></RadioItems>
          ))}
        {hasError && (
          <Notification variant='error' title={errorTitle}>
            <p>{errorMessage}</p>
          </Notification>
        )}

        {hasErrorInline && errorMessage && (
          <InlineErrorStyle>
            <IconNotificationError size='sm' />
            <span>{errorMessage}</span>
          </InlineErrorStyle>
        )}
      </Radio>
    </RadioGroupFieldset>
  );
};

export default FormRadioGroupRms;
