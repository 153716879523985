import React, { useEffect, useState } from 'react';
import { useStoreDispatch } from 'redux/store';
import { useHistory } from 'react-router-dom';
import { ComponentLoader } from '@snsw/react-component-library';
import {
  UserSession,
  UserToken,
  AnalyticsSessionManager,
  analyticsServiceV2,
  ErrorService,
} from '@rmstransactions/components';
import {
  TRANSACTION_STATE,
  REPLACE_LICENCE_URL,
  silentAuthUrl,
} from 'constants/constants';
import { getUserType } from 'services/ReplaceLicenceService';
import { userTypeSliceActions } from 'redux/slice/userType';
import pushAnalyticsNewPage from 'utils/session/pushAnalyticsNewPage/pushAnalyticsNewPage';
import transactionCleanupEvents from 'utils/session/transactionCleanupEvents/transactionCleanupEvents';
import { Config } from 'config/env';

const LandingPage: React.FC = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const isLoginRequired =
    urlSearchParams.has('isLoginRequired') &&
    urlSearchParams.get('isLoginRequired') === 'true';

  const handleRmsCustomerId = async () => {
    // get rmsCustomerId
    try {
      const response = await getUserType();
      const rmsCustomerId = response.data.rmsCustomerId;
      UserSession.resetSilentLoginCalled();
      UserSession.setActiveSession();
      UserToken.setValue(response.headers['x-token']);
      dispatch(userTypeSliceActions.setRmsCustomerId(rmsCustomerId));

      // setup Google analytics
      if (response.data.sessionId) {
        AnalyticsSessionManager.setSessionID(response.data.sessionId);
      }
      if (response.data.myAccountId) {
        AnalyticsSessionManager.setUserID(response.data.myAccountId);
      }
      if (response.data.type) {
        const analyticsUserLoginType =
          analyticsServiceV2.analyticsUserLoginMapedValues[response.data.type];
        AnalyticsSessionManager.setUserLoginType(analyticsUserLoginType);
      }
      AnalyticsSessionManager.setTransactionStartTime();
      pushAnalyticsNewPage();

      setIsLoading(false);
      history.push('/licence');
    } catch (error) {
      setIsLoading(false);
      history.push('/error');
      return;
    }
  };

  useEffect(() => {
    setIsLoading(true);

    if (UserSession.isSilentLoginCalled()) {
      UserSession.resetSilentLoginCalled();
      if (isLoginRequired === false) {
        handleRmsCustomerId();
      } else {
        UserSession.resetActiveSession();
        const error = { message: '401 Unauthorized' };
        ErrorService.handleAPIErrorForAuthTrans(
          error,
          history,
          Config.apiHost,
          TRANSACTION_STATE,
          REPLACE_LICENCE_URL
        );
      }
      setIsLoading(false);
    } else {
      // redirect to silent login
      UserSession.setSilentLoginCalled();
      UserSession.resetLoggedOutUser();
      UserSession.resetActiveSession();
      // reset Google Analytics session values
      transactionCleanupEvents();
      UserSession.silentLogin(silentAuthUrl);
    }
  }, [dispatch]);

  if (isLoading) {
    return (
      <ComponentLoader
        label='Processing, please wait. Do not refresh or navigate away.'
        fullPage
      />
    );
  }
  return null;
};

export default LandingPage;
