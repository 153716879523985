import React from 'react';
import { Spacer } from '@rmstransactions/components';
import Loader from '../SkeletonLoader/Loader';
import { CustomerDetailsWrapper } from './CustomerDetails.styled';
import { ShowLicenceCategoryProps } from './CustomerDetails';

export const CustomerDetailsSkeleton: React.FC<ShowLicenceCategoryProps> = ({
  showLicenceCategory,
}) => {
  return (
    <CustomerDetailsWrapper>
      <Spacer mb='1.5rem'>
        <Loader />
        <Loader />
      </Spacer>
      {showLicenceCategory && (
        <>
          {' '}
          <Loader />
          <Loader />
          <Loader />
        </>
      )}
    </CustomerDetailsWrapper>
  );
};
