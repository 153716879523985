import React from 'react';
import { FormRadioGroupRms } from '@rmstransactions/components';
import { validateSliceActions } from 'redux/slice/validate';
import { useStoreDispatch, useStoreSelector } from 'redux/store';
import { ConcessionCardTypeDefinition } from '../YourLicenceHelper';
import LostStolenDataBreachInfo from '../Contents/LostStolenDataBreachInfo';
export type callValidationFn = (
  concessionCardType: ConcessionCardTypeDefinition | '',
  reason: string | ''
) => void;
type setNoReasonSelectedFn = (any) => void;

interface IReplacementReasonProps {
  validateFunction: callValidationFn;
  noReasonSelected: boolean;
  setNoReasonSelected: setNoReasonSelectedFn;
  replacementReason: string | '';
}

const reasonsToShow = ['Lost', 'Stolen', 'Destroyed'];

export const ReplacementReasons: React.FC<IReplacementReasonProps> = ({
  validateFunction,
  noReasonSelected,
  setNoReasonSelected,
  replacementReason,
}): JSX.Element => {
  const dispatch = useStoreDispatch();
  const { replacementReasons, concessionCardType } = useStoreSelector(
    (state) => ({
      replacementReasons: state.eligibility.replacementReasons,
      concessionCardType: state.concessionCard.concessionType,
    })
  );
  const reasons = replacementReasons || reasonsToShow;

  const hasDataBreachLostStolenSelected = (reason: string | null) =>
    reason && replacementReason === reason && reason !== 'Destroyed';

  const setReplacementReason = (reason: string | '') => {
    dispatch(validateSliceActions.setReplacementReason(reason));
    setNoReasonSelected(false);
    validateFunction(concessionCardType, reason);
  };

  return (
    <>
      {reasons.map((reason, id) => {
        return (
          <>
            <FormRadioGroupRms
              key={id}
              id={'licence-reason-radio-group' + id}
              name='What_happened_to_your_NSW_driver_licence_card?'
              options={[
                {
                  value: reason,
                  label: reason[0] + reason.slice(1).toLowerCase(),
                },
              ]}
              className='FormRadioGroupRmsStyle'
              legend=''
              hasError={false}
              errorMessage={
                id === reasons.length - 1
                  ? 'Please select an option above to continue'
                  : ''
              }
              errorTitle=''
              hasErrorInline={noReasonSelected}
              vertical
              value={replacementReason}
              onChange={(e) => {
                setReplacementReason(e);
              }}
            />
            {hasDataBreachLostStolenSelected(reason) && (
              <LostStolenDataBreachInfo />
            )}
          </>
        );
      })}
    </>
  );
};
