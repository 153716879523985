import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILicenceCategoryLicenceApi } from 'interfaces/api/response/ILicence';

export interface licenceDetails {
  licenceCategory: ILicenceCategoryLicenceApi[];
  licenceNumber: string | null;
  status: string | null;
}

export const initialState: licenceDetails = {
  licenceCategory: null,
  licenceNumber: null,
  status: null,
};

export const licenceSlice = createSlice({
  name: 'licence',
  initialState,
  reducers: {
    setLicenceDetails: (
      state,
      action: PayloadAction<{
        licenceCategory: ILicenceCategoryLicenceApi[];
        licenceNumber: string;
      }>
    ) => {
      const { licenceCategory, licenceNumber } = action.payload;
      state.licenceCategory = licenceCategory;
      state.licenceNumber = licenceNumber;
      state.status = 'success';
    },
    resetLicenceDetails: () => initialState,
  },
});

export const licenceSliceActions = licenceSlice.actions;
export const licenceReducer = licenceSlice.reducer;
