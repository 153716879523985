import { Spacer } from '@rmstransactions/components';
import React from 'react';
import { InPageAlertHeaderOverride } from '../AlmostDonePage.style';
import {
  legallyDriveDownloadContent,
  serviceCenterLink,
} from 'utils/helpers/ErrorHandlers/helpers/errorContentDetails';
import { serviceCenterPhoneNumber } from 'utils/shared/shared';
import { checkForWarningsAndErrors } from 'utils/helpers/checkForWarningsAndErrors';
import { ILicenceCategoryLicenceApi } from 'interfaces/api/response/ILicence';

interface AlertProps {
  licenceCategory: ILicenceCategoryLicenceApi[];
}

const AlertActiveRefusalSanctionWarning: React.FC<AlertProps> = ({
  licenceCategory,
}) => {
  let isActiveRefusalSanctionWarningPresent: boolean;

  if (licenceCategory != null) {
    const { warningCodes } = checkForWarningsAndErrors(licenceCategory);
    isActiveRefusalSanctionWarningPresent =
      warningCodes.includes('ACTIVE_REFUSAL_PRESENT') ||
      warningCodes.includes('ACTIVE_SANCTION_EXISTS');
  }

  return (
    <>
      {isActiveRefusalSanctionWarningPresent && (
        <Spacer mt={2}>
          <InPageAlertHeaderOverride
            variant='warning'
            title={`Restrictions on your NSW Driver Licence`}
          >
            <div>
              <p>
                Due to restrictions on your licence you are only eligible for an
                Interim Driver Licence and will not receive a new NSW Driver
                Licence card. Your Interim Driver Licence is valid for one month
                from the issue date{' '}
                <b>
                  or until your licence expiry date (whichever comes first).
                </b>
              </p>{' '}
              <Spacer mt={1}>{legallyDriveDownloadContent}</Spacer>{' '}
              <Spacer mt={1} mb={1}>
                <p>
                  Need more information? Call us on {serviceCenterPhoneNumber},
                  or visit a {serviceCenterLink}.
                </p>
              </Spacer>{' '}
            </div>
          </InPageAlertHeaderOverride>
        </Spacer>
      )}
    </>
  );
};

export default AlertActiveRefusalSanctionWarning;
