import { Spacer } from '@rmstransactions/components';
import React from 'react';
import { InPageAlertHeaderOverride } from '../AlmostDonePage.style';
import {
  appealOnlineLink,
  downloadDigitalDriverLicenceLink,
  serviceCenterLink,
} from 'utils/helpers/ErrorHandlers/helpers/errorContentDetails';
import { serviceCenterPhoneNumber } from 'utils/shared/shared';
import { checkForWarningsAndErrors } from 'utils/helpers/checkForWarningsAndErrors';
import { ILicenceCategoryLicenceApi } from 'interfaces/api/response/ILicence';

interface AlertProps {
  licenceCategory: ILicenceCategoryLicenceApi[];
}

const AlertPendingCancellationOrSuspension: React.FC<AlertProps> = ({
  licenceCategory,
}) => {
  let licStatus: string;
  let isLicencePendingCancellationOrSuspension: boolean;

  if (licenceCategory != null) {
    const { warningCodes } = checkForWarningsAndErrors(licenceCategory);
    isLicencePendingCancellationOrSuspension =
      warningCodes.includes('LIC_PENDING_CANC') ||
      warningCodes.includes('LIC_PENDING_SUSPENSION');
    if (warningCodes.includes('LIC_PENDING_CANC')) {
      licStatus = 'cancellation';
    } else {
      licStatus = 'suspension';
    }
  }

  return (
    <>
      {isLicencePendingCancellationOrSuspension && (
        <Spacer mt={2}>
          <InPageAlertHeaderOverride
            variant='warning'
            title={`Your NSW Driver Licence is pending ${licStatus}`}
          >
            <div>
              <p>
                You will still receive a new licence card but you must not drive
                if your licence is suspended or cancelled.
              </p>{' '}
              <Spacer mt={1}>
                <p>
                  For further assistance, you may lodge an {appealOnlineLink}.
                </p>
              </Spacer>{' '}
              <Spacer mt={1} mb={1}>
                <p>
                  To legally drive, you{' '}
                  <b>must print and carry your Interim Driver Licence</b> or
                  download your {downloadDigitalDriverLicenceLink} from the
                  Service NSW app.
                </p>
              </Spacer>{' '}
              Need more information? Call us on {serviceCenterPhoneNumber}, or
              visit a {serviceCenterLink}.
            </div>
          </InPageAlertHeaderOverride>
        </Spacer>
      )}
    </>
  );
};

export default AlertPendingCancellationOrSuspension;
