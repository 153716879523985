import styled from 'styled-components';
import { tokens, Heading } from '@snsw/react-component-library';
import { Size } from '@rmstransactions/components/Styled';

export const TitleText = styled.p`
  font-size: 1.125rem;
  margin: 0;
`;

export const PrivacyWrapper = styled.div`
  display: flex;
  padding: 0.75rem 1.5rem;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  position: relative;
  box-shadow: 0px 0px 8px ${tokens.colors.grey.geyser};
  border-radius: 8px;
  @media only screen and (max-width: ${Size.md}px) {
    flex-flow: row wrap;
  }
`;

export const QuestionCardContainer = styled.div`
  border-radius: 6px;
  border: 0.125rem solid rgb(222, 227, 229);
  margin: 0.625rem 0;
  padding: 0.5rem 1.5rem 1rem 1.5rem;
`;

export const HeadingOverride = styled(Heading)`
  margin: 0;
`;
