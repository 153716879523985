import { IMessage } from 'interfaces/api/response/IMessage';

export type ErrorCodeValueType =
  | 'LIC_SURRENDERED'
  | 'LIC_EXPIRED'
  | 'DRIVING_LIC_DUE_FOR_EXP'
  | 'REPLACEMENT_REQUESTED_LAST_12_MONTHS'
  | 'LIC_STATUS_DISQUALIFIED'
  | 'LIC_STATUS_CANCELLED'
  | 'LIC_STATUS_SUSPENDED'
  | 'CUST_ADDR_UPDATED'
  | 'ACTIVE_PHOTOCARD_FOUND'
  | 'ADDRESS_SUPPRESSED'
  | 'CUSTOMER_HAS_CAMS'
  | 'CUSTOMER_HAS_UNCLASSIFIED_RESI_STATUS'
  | 'CUSTOMER_NOT_FOUND'
  | 'CUSTOMER_HAS_NO_LICENCE'
  | 'MPS_CARD_FOUND_NO_COND'
  | 'CUST_RECD_LIC_IN_14_DAYS'
  | 'LIC_PENDING_CANC'
  | 'LIC_PENDING_SUSPENSION'
  | 'VALID_IMAGE_NOT_EXIST'
  | 'ADMIN_INELIGIBILITY_EXISTS'
  | 'SYSTEM_FAILURE_GENERIC'
  | 'SYSTEM_FAILURE'
  | 'ACTIVE_REFUSAL_PRESENT'
  | 'ACTIVE_SANCTION_EXISTS'
  | 'SCHEMA_VALIDATION_ERROR'
  | 'INVALID_INPUT'
  | 'INVALID_MSG_SOLN'
  | 'PENSIONER_CHECK_DEFERRED_ON_PENSIONER_VALIDATION'
  | 'NOT_ELIGIBLE_FOR_PENSION_CONCESSION'
  | 'NOT_ELIGIBLE'
  | 'DECL_ALREADY_EXISTS'
  | 'ATTEND_SERVICE_CENTRE'
  | 'VISIT_SERVICE_CENTRE'
  | 'WARNING'
  | 'APPEALED_SUSPENSION_PRESENT'
  | 'APPEALED_CANC_PRESENT'
  | 'MULTIPLE_DECL_FOUND'
  | 'ERROR';

export const ErrorCodes: Record<ErrorCodeValueType, string> = {
  LIC_SURRENDERED: 'LIC_SURRENDERED',
  LIC_EXPIRED: 'LIC_EXPIRED',
  DRIVING_LIC_DUE_FOR_EXP: 'DRIVING_LIC_DUE_FOR_EXP',
  REPLACEMENT_REQUESTED_LAST_12_MONTHS: 'REPLACEMENT_REQUESTED_LAST_12_MONTHS',
  LIC_STATUS_DISQUALIFIED: 'LIC_STATUS_DISQUALIFIED',
  LIC_STATUS_CANCELLED: 'LIC_STATUS_CANCELLED',
  LIC_STATUS_SUSPENDED: 'LIC_STATUS_SUSPENDED',
  CUST_ADDR_UPDATED: 'CUST_ADDR_UPDATED',
  ACTIVE_PHOTOCARD_FOUND: 'ACTIVE_PHOTOCARD_FOUND',
  ADDRESS_SUPPRESSED: 'ADDRESS_SUPPRESSED',
  CUSTOMER_HAS_CAMS: 'CUSTOMER_HAS_CAMS',
  CUSTOMER_HAS_UNCLASSIFIED_RESI_STATUS:
    'CUSTOMER_HAS_UNCLASSIFIED_RESI_STATUS',
  CUSTOMER_NOT_FOUND: 'CUSTOMER_NOT_FOUND',
  CUSTOMER_HAS_NO_LICENCE: 'CUSTOMER_HAS_NO_LICENCE',
  MPS_CARD_FOUND_NO_COND: 'MPS_CARD_FOUND_NO_COND',
  CUST_RECD_LIC_IN_14_DAYS: 'CUST_RECD_LIC_IN_14_DAYS',
  VALID_IMAGE_NOT_EXIST: 'VALID_IMAGE_NOT_EXIST',
  LIC_PENDING_CANC: 'LIC_PENDING_CANC',
  LIC_PENDING_SUSPENSION: 'LIC_PENDING_SUSPENSION',
  ADMIN_INELIGIBILITY_EXISTS: 'ADMIN_INELIGIBILITY_EXISTS',
  SYSTEM_FAILURE_GENERIC: 'SYSTEM_FAILURE_GENERIC',
  SYSTEM_FAILURE: 'SYSTEM_FAILURE',
  ACTIVE_REFUSAL_PRESENT: 'ACTIVE_REFUSAL_PRESENT',
  ACTIVE_SANCTION_EXISTS: 'ACTIVE_SANCTION_EXISTS',
  SCHEMA_VALIDATION_ERROR: 'SCHEMA_VALIDATION_ERROR',
  INVALID_INPUT: 'INVALID_INPUT',
  INVALID_MSG_SOLN: 'INVALID_MSG_SOLN',
  PENSIONER_CHECK_DEFERRED_ON_PENSIONER_VALIDATION:
    'PENSIONER_CHECK_DEFERRED_ON_PENSIONER_VALIDATION',
  NOT_ELIGIBLE_FOR_PENSION_CONCESSION: 'NOT_ELIGIBLE_FOR_PENSION_CONCESSION',
  NOT_ELIGIBLE: 'NOT_ELIGIBLE',
  DECL_ALREADY_EXISTS: 'DECL_ALREADY_EXISTS',
  ATTEND_SERVICE_CENTRE: 'ATTEND_SERVICE_CENTRE',
  VISIT_SERVICE_CENTRE: 'VISIT_SERVICE_CENTRE',
  APPEALED_SUSPENSION_PRESENT: 'APPEALED_SUSPENSION_PRESENT',
  APPEALED_CANC_PRESENT: 'APPEALED_CANC_PRESENT',
  MULTIPLE_DECL_FOUND: 'MULTIPLE_DECL_FOUND',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
};

export type DualClassErrorCodeValueType =
  | 'LIC_STATUS_DISQUALIFIED'
  | 'LIC_STATUS_CANCELLED'
  | 'LIC_STATUS_SUSPENDED'
  | 'LIC_EXPIRED'
  | 'APPEALED_SUSPENSION_PRESENT'
  | 'APPEALED_CANC_PRESENT';

export const DualClassErrorCodes: Record<DualClassErrorCodeValueType, string> =
  {
    LIC_EXPIRED: 'LIC_EXPIRED',
    LIC_STATUS_DISQUALIFIED: 'LIC_STATUS_DISQUALIFIED',
    LIC_STATUS_CANCELLED: 'LIC_STATUS_CANCELLED',
    LIC_STATUS_SUSPENDED: 'LIC_STATUS_SUSPENDED',
    APPEALED_SUSPENSION_PRESENT: 'APPEALED_SUSPENSION_PRESENT',
    APPEALED_CANC_PRESENT: 'APPEALED_CANC_PRESENT',
  };

export type ErrorDetailType = {
  pageHeading: string;
  title: string;
  description: JSX.Element;
  statusLabel: string;
  info: IInfo[];
};
export type DualClassErrorType = {
  title: string;
  description: JSX.Element;
};

export type ErrorMessageType = Record<ErrorCodeValueType, ErrorDetailType>;
export type DualClassErrorMessageType = Record<
  DualClassErrorCodeValueType,
  DualClassErrorType
>;

export type SuccessCodeValueType = 'SUCCESS';

type CodeValueType = ErrorCodeValueType | SuccessCodeValueType;

export type CodeType = 'Code' | 'Identifier' | 'severity';

export interface IInfo {
  title: string;
  description: string;
}

export const matchCodeInMessages = (
  messages: IMessage[] | IMessage | null,
  matchingField: CodeType,
  toMatch: CodeValueType
): IMessage | IMessage[] | null => {
  try {
    if (messages && Array.isArray(messages)) {
      const filteredMessages: IMessage[] = []
      for(const msg of messages){
        if(msg[matchingField] === toMatch){
          filteredMessages.push(msg)
        }
      }
      if(filteredMessages.length>0) return filteredMessages; else return null
    } else {
      if (messages && messages[matchingField] === toMatch) {
        return messages as IMessage;
      }
    }
    return null;
  } catch (error) {
    return null;
  }
};
