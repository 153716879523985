import { IProgressBarStep } from '../../interfaces/IProgressBarStep';

export const stepsList: IProgressBarStep[][] = [
  [
    {
      content: 'Your licence',
      status: 'current',
    },
    {
      content: 'Declaration',
      status: 'todo',
    },
    {
      content: 'Review and confirm',
      status: 'todo',
    },
    {
      content: 'Make a payment',
      status: 'todo',
    },
  ],
  [
    {
      content: 'Your licence',
      status: 'completed',
    },
    {
      content: 'Declaration',
      status: 'current',
    },
    {
      content: 'Review and confirm',
      status: 'todo',
    },
    {
      content: 'Make a payment',
      status: 'todo',
    },
  ],
  [
    {
      content: 'Your licence',
      status: 'completed',
    },
    {
      content: 'Declaration',
      status: 'completed',
    },
    {
      content: 'Review and confirm',
      status: 'current',
    },
    {
      content: 'Make a payment',
      status: 'todo',
    },
  ],
  [
    {
      content: 'Your licence',
      status: 'completed',
    },
    {
      content: 'Declaration',
      status: 'completed',
    },
    {
      content: 'Review and confirm',
      status: 'completed',
    },
    {
      content: 'Make a payment',
      status: 'current',
    },
  ],
];
