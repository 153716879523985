import React from 'react';
import { TNCList } from '../style/TNCList.styled';

const TNCs = {
  1: `These Terms apply: These Terms and Conditions, together with the Terms of use, Copyright statement and Privacy statement published on the Service NSW website (“Terms”), set out the conditions that apply to your use of the records enquiry service (“Service”). By using the Service you agree to comply with these Terms together with all relevant guidelines, instructions and procedures (“Instructions”) posted on this site. Nothing in these Terms limits or qualifies your rights or obligations under any legislation, including legislation relating to motor vehicles, traffic, roads or road transport.`,
  2: `Changing these Terms: Transport for NSW and Service NSW may change these Terms, and their Instructions from time to time by republishing them online. You agree that by doing this, you have been provided you with sufficient notice of the variation. You should read the Terms and Instructions each time before you use the Service in case they have changed. If you do not agree to any change, you should not continue to use the Service.`,
  3: `Concession eligibility declaration: If you are claiming a concession on your fees, you declare that you are eligible for that concession. You acknowledge and agree that your application for a concession is subject to verification of your concession entitlement and you agree to promptly provide all necessary information to assist with verification. If you pay a fee at a concessional rate but your claimed concession cannot be verified then within 14 days of being requested you must pay the difference between the full fee and the amount you paid.`,
  4: `Correct information: You warrant that all information, including personal information, that you provide is accurate, true and complete and, to the extent that you disclose information about other people, you are authorised to disclose it to us for the purposes for which you have disclosed it. You are responsible for any additional expenses you may incur if you submit incorrect information and Transport for NSW or Service NSW acts upon it. For example, if you request a replacement Roads and Maritime Services product in relation to the wrong vehicle registration.`,
  5: `Security of information: You are responsible for taking reasonable care in how you access the Service, including using appropriate firewalls and anti-virus software. No data transmission over the Internet can be guaranteed as totally secure and communications to this Service may be intercepted or altered in transit. Although Transport for NSW and Service NSW strive to protect such information, they do not represent or warrant and cannot ensure the security of any information which is transmitted when you use the Service. When using this Service you transmit and receive information at your own risk and Roads and Maritime Services and Service NSW bear no liability.`,
  6: `Unavailability of Service: You acknowledge and agree that from time to time the Service may be unavailable.`,
  7: `Payment: All amounts are stated in Australian dollars and are inclusive of GST. You agree that if you do not pay the required amount (including where your financial institution declines or reverses payment or has not made the payment because you have not complied with its terms and conditions), we may not provide you with the ordered product or service, or may reverse the relevant transaction and treat your order as not having been placed.`,
  8: `Age: You warrant that you are aged 18 or over. If you are under 18, you may ask us to provide the Service only with the involvement of a parent or guardian.`,
  9: `Jurisdiction: These Terms, and your application to use the Service are governed by the laws of New South Wales, Australia, and you irrevocably submit to the jurisdiction of the courts of New South Wales.`,
  10: ` General: A reference to any legislation includes all delegated legislation made under it, including regulations and rules, and amendments, consolidations, replacements or re-enactments of any of them. A reference to an entity, whether statutory or not, which ceases to exist or whose powers or functions are transferred to another body is a reference to the body which replaces it or which substantially succeeds to its powers or functions. Specifying anything in these Terms after the words ‘include’ or ‘for example’ or similar expressions does not limit what else is included.`,
};

const TermsAndConditionModalContent: React.FC = () => {
  return (
    <ol>
      {Object.keys(TNCs).map((index) => {
        return <TNCList key={index}>{TNCs[index]}</TNCList>;
      })}
    </ol>
  );
};

export default TermsAndConditionModalContent;
