import React from 'react';
import {
  CHANGE_ADDRESS_NSW_GOVERNMENT,
  INTERIM_DRIVER_LICENCE_URL,
  RENEW_OR_UPGRADE_LICENCE_URL,
} from '../../../constants/constants';
import { serviceCenterPhoneNumber } from 'utils/shared/shared';
import { serviceCenterLink } from 'utils/helpers/ErrorHandlers/helpers/errorContentDetails';
const HelpText: React.FC = () => {
  return (
    <>
      <p>
        Unfortunately, you are not able to replace your NSW Driver Licence card
        online unless it was lost, stolen, destroyed or impacted by a data
        breach.
      </p>
      <p>
        Please call us on {serviceCenterPhoneNumber}, or visit a{' '}
        {serviceCenterLink} in order to have your NSW Driver Licence card
        replaced.
      </p>
      <p>You may also be interested in the following transactions:</p>
      <ul>
        <li>
          <a
            href={CHANGE_ADDRESS_NSW_GOVERNMENT}
            target='_blank'
            rel='noopener noreferrer'
          >
            Change of address
          </a>
        </li>
        <li>
          <a
            href={RENEW_OR_UPGRADE_LICENCE_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            Renew or upgrade a NSW Driver Licence
          </a>
        </li>
        <li>
          <a
            href={INTERIM_DRIVER_LICENCE_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            Reprint a paper Interim Licence
          </a>
        </li>
      </ul>
    </>
  );
};

export default HelpText;
