import React from 'react';
import { Header } from '@rmstransactions/components';
import {
  InPageAlert,
  ContentContainer,
  Col,
  Row,
  Button,
} from '@snsw/react-component-library';
import { TECHNICAL_ERROR_PAGE_HEADER } from 'registration-renewal/src/constants/constants';
import { needHelpDescription } from 'utils/helpers/ErrorHandlers/helpers/errorContentDetails';
import { ButtonContainer } from './components/ErrorLayout.styled';
import { goToLogin, gotoDrupal } from 'utils/helpers/helpers';

const LoggedOutUserErrorPage: React.FC = () => {
  const handleClickLogInBtn = (event) => {
    event.preventDefault();
    goToLogin();
  };

  const handleClickQuitBtn = (event) => {
    event.preventDefault();
    gotoDrupal();
  };

  return (
    <>
      <Header title={TECHNICAL_ERROR_PAGE_HEADER} data-testid='header' />
      <ContentContainer>
        <Row>
          <Col span={8}>
            <InPageAlert variant='error' title='Access error occured'>
              You have been logged out or do not have access to this page.
              Please log back into your account and try again.
              {needHelpDescription}
            </InPageAlert>
            <ButtonContainer>
              <Button
                onClick={handleClickLogInBtn}
                className='buttons'
                data-testid='Find-a-service-centre-button'
              >
                Log in and continue
              </Button>
              <Button
                onClick={handleClickQuitBtn}
                theme='secondary'
                className='buttons'
              >
                Quit
              </Button>
            </ButtonContainer>
          </Col>
        </Row>
      </ContentContainer>
    </>
  );
};

export default LoggedOutUserErrorPage;
