import { Config } from 'config/env';

export const TRANSACTION_STATE = 'REPL-LIC';
export const CHANGE_ADDRESS_NSW_GOVERNMENT = 'https://www.service.nsw.gov.au/transaction/change-address-nsw-government';
export const REPLACE_LICENCE_URL =
  'https://www.service.nsw.gov.au/transaction/replace-nsw-driver-licence-online';
export const MYACCOUNT_LOGIN_URL = `${Config.apiHost}/login?state=${TRANSACTION_STATE}`;
export const DRUPAL_FIRST_PAGE_URL =
  process.env.REACT_APP_MY_ACCOUNT_BASE_URL + '/api/auth/logout';
export const RENEW_LICENCE_URL =
  'https://www.service.nsw.gov.au/transaction/renew-or-upgrade-a-nsw-driver-licence';
export const RENEW_OR_UPGRADE_LICENCE_URL =
  'https://www.service.nsw.gov.au/transaction/renew-or-upgrade-nsw-driver-licence';
export const BUSINESS_TRANSACTION = 'ReplaceLicence';
export const SERVICE_CENTRE_URL =
  'https://www.service.nsw.gov.au/service-centre';
export const LICENCE_TYPE_U = 'U';
export const LICENCE_TYPE_FULL = 'Full';
export const receiptFileName = 'receipt.pdf';

export const sessionStorageKeyForReceiptUUID = 'replaceLicenceReceiptUUID';

export enum InputValidationErrors {
  MAX_LENGTH_REACHED = 'MAX_LENGTH_REACHED',
  SPECIAL_CHARACTER = 'SPECIAL_CHARACTER',
  EMPTY_CONCESSION_CARD_NOT_ALLOWED = 'EMPTY_CONCESSION_CARD_NOT_ALLOWED',
}

export const silentAuthUrl = `${Config.apiHost}${Config.apiSilentLoginPath}?state=${TRANSACTION_STATE}`;

export const NSW_APP_URL =
  'https://www.service.nsw.gov.au/service-nsw-mobile-app';
export const DIGITAL_DRIVER_LICENCE_URL =
  'https://www.service.nsw.gov.au/campaign/nsw-digital-driver-licence';
export const INTERIM_DRIVER_LICENCE_URL =
  'https://www.service.nsw.gov.au/transaction/reprint-nsw-driver-licence-receipt';
export const HEAVY_PENALTIES_URL =
  'https://www.nsw.gov.au/driving-boating-and-transport/demerits-penalties-and-offences/offences/licence-and-registration-offences-and-penalties';
export const LATE_LICENCE_RENEWAL_URL =
  'https://www.nsw.gov.au/driving-boating-and-transport/driver-and-rider-licences/renewing-or-replacing-your-licence/early-or-late-renewal#toc-late-licence-renewal';
export const EYESIGHT_TEST_URL =
  'https://www.nsw.gov.au/driving-boating-and-transport/driver-and-rider-licences/health-conditions-and-disability/fitness-to-drive/eyesight-testing';
export const HOW_DEMERIT_POINTS_WORK_URL =
  'https://www.nsw.gov.au/driving-boating-and-transport/demerits-penalties-and-offences/how-demerit-points-work';
export const HOW_MEDICAL_REQUIREMENTS_WORK_URL =
  'https://www.nsw.gov.au/driving-boating-and-transport/driver-and-rider-licences/health-conditions-and-disability/fitness-to-drive';
export const FITNESS_TO_DRIVE_ASSESMENT_PROCESS_URL =
  'https://www.nsw.gov.au/driving-boating-and-transport/driver-and-rider-licences/health-conditions-and-disability/fitness-to-drive/medical-assessments';
export const SUSPENSION_DISQUALIFICATION_URL =
  'https://www.nsw.gov.au/driving-boating-and-transport/demerits-penalties-and-offences/how-demerit-points-work/licence-suspension-and-disqualification';
export const LODGE_APPEAL_ONLINE_URL =
  'https://onlineregistry.lawlink.nsw.gov.au/Landing.html?redir=/content/%3f';
export const DRIVING_RECORD_ONLINE_URL =
  'https://www.service.nsw.gov.au/transaction/request-a-driving-record';
export const NSW_PHOTO_CARD_URL =
  'https://www.service.nsw.gov.au/transaction/apply-for-a-nsw-photo-card';
export const CHANGE_LICENCE_OR_CUSTOMER_NUMBER_URL =
  'https://www.nsw.gov.au/driving-boating-and-transport/driver-and-rider-licences/proof-of-identity/changing-your-licence-or-customer-number';