import React from 'react';
import { MoreInfoModal, InPageAlert } from '@snsw/react-component-library';
import {
  MoreInfoModalWrapper,
  ProtectIdentityDescription,
  TextLinkStyle,
  AnimationStyle,
} from '../YourLicence.styled';
import { CHANGE_LICENCE_OR_CUSTOMER_NUMBER_URL } from 'constants/constants';
import { Spacer } from '@rmstransactions/components';

const LostStolenDataBreachInfo: React.FC = () => {
  const changeLicenceOrCustomerNumberUrl = (
    <TextLinkStyle
      href={CHANGE_LICENCE_OR_CUSTOMER_NUMBER_URL}
      target='_blank'
      rel='noopener noreferrer'
    >
      apply for a new licence/customer number
    </TextLinkStyle>
  );

  const helpTextValue = (
    <div>
      <Spacer mb={1}>
        When you&apos;re replacing your licence the licence number remains the
        same but your physical card number changes. This protects you from
        unauthorised Document Verification Service (DVS) checks using the old
        card&apos;s information.
      </Spacer>
      <Spacer mb={1}>
        A DVS check is used by institutions such as banks to verify a
        person&apos;s identity.
      </Spacer>
      <Spacer mb={1}>
        Both the driver licence number and the card number are required to pass
        a DVS check for NSW licence holders.
      </Spacer>
      <Spacer mb={1}>
        In NSW, increased identity protections came into effect on 1 September
        2022, to help guard against unauthorised use of a driver licence for
        identification purposes.
      </Spacer>
      <Spacer mb={1}>
        Since that date, both numbers on your driver licence, the licence number
        and the card number, are required to pass a Document Verification
        Service check.
      </Spacer>
      Under exceptional circumstances, you may be eligible to{' '}
      {changeLicenceOrCustomerNumberUrl}. Please note that this process may take
      up to 6 months, there are additional fees and it is not guaranteed you
      will be granted a new licence number.
    </div>
  );
  return (
    <AnimationStyle>
      <InPageAlert
        variant='info'
        title='Replacing your licence will help protect your identity'
      >
        <ProtectIdentityDescription>
          When you replace your licence, your driver licence number remains the
          same but your card number changes. You do not need both numbers to be
          updated to be protected from fraudulent activities.
        </ProtectIdentityDescription>
        <MoreInfoModalWrapper>
          <MoreInfoModal
            linkText='Learn more'
            questionHyperLink
            title='Protecting your identity'
            helpText={helpTextValue}
          />
        </MoreInfoModalWrapper>
      </InPageAlert>
    </AnimationStyle>
  );
};

export default LostStolenDataBreachInfo;
