import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUserTypeDetails {
  rmsCustomerId: string | null;
}

export const initialState: IUserTypeDetails = {
  rmsCustomerId: null,
};

export const userTypeSlice = createSlice({
  name: 'customerId',
  initialState,
  reducers: {
    setRmsCustomerId: (state, action: PayloadAction<string>) => {
      state.rmsCustomerId = action.payload;
    },
    resetRmsCustomerId: () => initialState,
  },
});

export const userTypeSliceActions = userTypeSlice.actions;
export const userTypeReducer = userTypeSlice.reducer;
