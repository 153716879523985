import { IMessage } from 'interfaces/api/response/IMessage';
import { uniqBy } from './helpers';
import { ILicenceCategoryLicenceApi } from 'interfaces/api/response/ILicence';
import {
  ErrorCodes,
  IInfo,
  matchCodeInMessages,
} from './ErrorHandlers/helpers/helpers';
import { LicenceStatusDetails } from './ErrorHandlers/apiErrorCheck';

interface ErrorsAndWarnings {
  errorCodes: string[];
  warningCodes: string[];
}

interface ErrorsAndWarningsWithInfomation extends ErrorsAndWarnings {
  hasError: boolean;
  licenceStatusForErrorCodes: string[];
  showInfoArray: IInfo[];
}

export const checkForWarningsAndErrors = (
  messagesObject: ILicenceCategoryLicenceApi[]
): ErrorsAndWarningsWithInfomation => {
  const infoArray: IInfo[] = [];
  const errorCodes: string[] = [];
  const warningCodes: string[] = [];
  const licenceStatusForErrorCodes: string[] = [];
  let foundError = false;

  for (const licDetails of messagesObject) {
    const findWarnings: IMessage | IMessage[]  = matchCodeInMessages(
      licDetails.systemMessages?.message,
      'severity',
      'WARNING'
    );

    if (findWarnings && Array.isArray(findWarnings)) {
      for(const msg of findWarnings){
        warningCodes.push(msg.identifier);
      }
    }

    const findErrors: IMessage | IMessage[] = matchCodeInMessages(
      licDetails.systemMessages?.message,
      'severity',
      'ERROR'
    );

    if (findErrors) {
      foundError = true;
      errorCodes.push(findErrors[0].identifier);
      licenceStatusForErrorCodes.push(licDetails.licenceStatus);
      const errorCode = findErrors[0].identifier;
      const errorCodeInfo = LicenceStatusDetails[errorCode].info;

      // Note: if dual class and one expired
      // priority given for the suspended/disq/cancelled
      // therefore check if expired and push relevant information to the end
      // otherwise push relevant information in front of the array
      if (errorCode === ErrorCodes.LIC_EXPIRED) {
        infoArray.push(...errorCodeInfo);
      } else {
        infoArray.unshift(...errorCodeInfo);
      }
    }
  }

  const newInfoArray = uniqBy(infoArray, JSON.stringify);

  return {
    hasError: foundError,
    licenceStatusForErrorCodes,
    errorCodes,
    warningCodes,
    showInfoArray:
      errorCodes.length >= messagesObject.length && infoArray.length > 0
        ? newInfoArray
        : [],
  };
};


export const checkForWarningsAndErrorsInSystemMessages = (
  messages: IMessage[]
): ErrorsAndWarnings => {

  const errorCodes: string[] = [];
  const warningCodes: string[] = [];

  const findWarnings: IMessage | IMessage[] = matchCodeInMessages(
    messages,
    'severity',
    'WARNING'
  );

  if (findWarnings && Array.isArray(findWarnings)) {
    for(const msg of findWarnings){
      warningCodes.push(msg.identifier);
    }
  }

  const findErrors: IMessage[] | IMessage = matchCodeInMessages(
    messages,
    'severity',
    'ERROR'
  );

  if (findErrors)
    errorCodes.push(findErrors[0].identifier);

  return {
    errorCodes,
    warningCodes,
  };
};

