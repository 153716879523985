import React from 'react';
import { Radio } from '../../../Styled/FormRadioGroupStyleOverride';

const RadioItems = ({
  id,
  name,
  option,
  value,
  fieldType,
  onSelection,
  inputRef,
  hasErrorInline,
  mt,
  mb,
}) => {
  const handleClick = (e) => {
    if (e.target.tagName === 'INPUT') {
      e.target.checked = true;
      let selectedValue = e.target.value;
      if (fieldType === 'boolean') {
        selectedValue = stringToBoolean(selectedValue);
      }
      onSelection(selectedValue);
    }
  };

  const checked = value === undefined ? value : option.value === value;

  return (
    <Radio.Item
      onClick={handleClick}
      checked={checked}
      hasErrorInline={hasErrorInline}
      className='here'
      mt={mt}
      mb={mb}
    >
      <Radio.Input
        className={''}
        id={id}
        name={name}
        type='radio'
        value={option.value}
        checked={checked}
        ref={inputRef}
        aria-label='What happened to your NSW driver licence card?'
      ></Radio.Input>
      <Radio.Label checked={checked} hasErrorInline={hasErrorInline}>
        {option.label}
      </Radio.Label>
    </Radio.Item>
  );
};

export default RadioItems;
