export interface IDeclarationDetails {
  channel?: Channel;
  deviceUsed?: string;
  existingDeclaration: ExistingDeclaration;
  declarationID?: string;
  declarationResponses?: IDeclarationResponses;
}

export enum Channel {
  UNASSISTED = 'UNASSISTED',
  SELF_SERVICE = 'SELFSERVICE',
  STAFF_ASSISTED = 'STAFFASSISTED',
}

export enum ExistingDeclaration {
  Y = 'Y',
  N = 'N',
}

export interface IDeclarationResponses {
  declaration: IDeclarationResponse[];
}

export interface IDeclarationResponse {
  question: IQuestion;
  response: IResponse;
}

export interface IQuestion {
  questionId: number;
  questionText: string;
  displaySequence: number;
  displayOrder: number;
}

export interface IResponse {
  answerTitle: string;
  answer: string[];
}
