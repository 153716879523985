import { analyticsServiceV2 } from '@rmstransactions/components';

const { defaultAnalyticsNullValue } = analyticsServiceV2;

const transactionName = 'Driver licence';

export const stepLicencePage = 'Your licence';
export const stepDeclarationPage = 'Declaration';
export const stepReviewAndConfirmPage = 'Review and confirm';
export const stepAlmostDonePage = 'Make a payment';

// Google analytics
export const googleAnalyticsDefaultInfo = {
  transaction_name: transactionName,
  transaction_step: 'unknown',
  transaction_sub_type: defaultAnalyticsNullValue,
};

export const errorPath = '/error';

type TransactionInfo = {
  [key: string]: {
    transaction_name: string;
    transaction_type: string;
    transaction_step: string;
    transaction_sub_type: string | string[];
  };
};

export const generateTransactionInfo = (
  licenceType1: string,
  licenceType2: string
): TransactionInfo => {
  const vehicleTypeArray = [licenceType1, licenceType2];
  const filteredvehicleTypeArray = vehicleTypeArray.filter(
    (value) => value !== null && value !== undefined
  );
  return {
    [errorPath]: {
      transaction_name: transactionName,
      transaction_type: 'replace',
      transaction_step: 'Error',
      transaction_sub_type: 'api',
    },
    '/licence': {
      transaction_name: transactionName,
      transaction_type: 'replace',
      transaction_step: stepLicencePage,
      transaction_sub_type: filteredvehicleTypeArray,
    },
    '/declaration': {
      transaction_name: transactionName,
      transaction_type: 'replace',
      transaction_step: stepDeclarationPage,
      transaction_sub_type: filteredvehicleTypeArray,
    },
    '/review-and-confirm': {
      transaction_name: transactionName,
      transaction_type: 'replace',
      transaction_step: stepReviewAndConfirmPage,
      transaction_sub_type: filteredvehicleTypeArray,
    },
    '/almost-done': {
      transaction_name: transactionName,
      transaction_type: 'replace',
      transaction_step: stepAlmostDonePage,
      transaction_sub_type: filteredvehicleTypeArray,
    },
  };
};

export const googleAnalyticsViewReceiptInfo = {
  transaction_name: transactionName,
  transaction_type: 'replace',
  transaction_step: stepAlmostDonePage,
  transaction_sub_type: defaultAnalyticsNullValue,
};
export const googleAnalyticsUUIDAlmostDoneRedirectInfo = {
  transaction_name: transactionName,
  transaction_type: 'replace',
  transaction_step: 'payment gateway redirect',
  transaction_sub_type: defaultAnalyticsNullValue,
};
