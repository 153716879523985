import {
  activePhotoCardFoundDescription,
  callUsOrVisitDescription,
  cannotReplaceLicencePageHeading,
  changedAddressTitle,
  consessionCanNotValidateTitle,
  declarationAlreadyExistDescription,
  declarationAlreadyExistTitle,
  forMoreInformationDescription,
  ifYouNeedToReplaceIfHasRecentlyUpdatedAddressDescription,
  ifYouNeedToReplaceWithinTwelveMonthTwiceDescription,
  infoForDisqualifiedCancelledSuspended,
  infoForExpired,
  licenceOrderedInLast14DaysDescription,
  licencePendingCancelSuspension,
  licencePendingCancellationTitle,
  licencePendingSuspensionTitle,
  mayEligibleRenewOnlineDescription,
  mayEligibleRenewOnlineDueExpiryDescription,
  mpsCardFoundDescription,
  notEligibleForPensionConssesionTitle,
  notFoundTitle,
  pensionerValidationDeferredDescription,
  replacementLast12DaysTitle,
  replacementLast14DaysTitle,
  restrictedLicenceDescription,
  restrictedLicenceTitle,
  sorryAboutThisPageHeading,
  systemUnvailableDescription,
  systemUnvailableTitle,
  unableToverifyConssesionDescription,
  visitServiceCenterForPhoto,
  weNeedToCheckDetailsTitle,
  yourNswTitle,
  dualClassTitleOneExpired,
  dualClassDescriptionOneExpired,
  dualClassTitleOneSuspended,
  dualClassDescriptionOneSuspended,
  dualClassDescriptionOneDisqualified,
  dualClassDescriptionOneCancelled,
  dualClassTitleOneCancelled,
  dualClassTitleOneDisqualified,
  youCannotReplaceLicencePageHeading,
  notEligibleDescription,
  cannotProceedOnlineDescription,
  infoForCannotProceedOnline,
  yourDetailsNotFoundTitle,
  callUsOrVisitWithApology,
  visitServiceCentreDescription,
  singleClassExpiredCancelledDisqualifiedDesc,
  finishApplicationInPersonHeading,
  multipleDeclarationFoundDescription,
  cannotProceedOnlineTitle,
  noLicenceFoundTitle,
  dualClassTitlePendingCancSuspension,
  licencePendingCancOrSuspendedDualClass,
  dualClassHasActiveAndAppealedSanctionOrCancelPresentDescription,
} from './helpers/errorContentDetails';
import { DualClassErrorMessageType, ErrorMessageType } from './helpers/helpers';

export const LicenceStatusDetails: ErrorMessageType = {
  LIC_SURRENDERED: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: `${yourNswTitle} has been surrendered`,
    description: callUsOrVisitDescription,
    statusLabel: '',
    info: [],
  },
  REPLACEMENT_REQUESTED_LAST_12_MONTHS: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: replacementLast12DaysTitle,
    description: ifYouNeedToReplaceWithinTwelveMonthTwiceDescription,
    statusLabel: '',
    info: [],
  },
  LIC_STATUS_DISQUALIFIED: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: `${yourNswTitle} is disqualified`,
    description: forMoreInformationDescription,
    statusLabel: 'Disqualified',
    info: infoForDisqualifiedCancelledSuspended,
  },
  LIC_STATUS_CANCELLED: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: `${yourNswTitle} is cancelled`,
    description: forMoreInformationDescription,
    statusLabel: 'Cancelled',
    info: infoForDisqualifiedCancelledSuspended,
  },
  LIC_STATUS_SUSPENDED: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: `${yourNswTitle} is suspended`,
    description: forMoreInformationDescription,
    statusLabel: 'Suspended',
    info: infoForDisqualifiedCancelledSuspended,
  },
  DRIVING_LIC_DUE_FOR_EXP: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: `${yourNswTitle} expires in less than 6 weeks`,
    description: mayEligibleRenewOnlineDueExpiryDescription,
    statusLabel: '',
    info: [],
  },
  LIC_EXPIRED: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: `${yourNswTitle} is expired`,
    description: mayEligibleRenewOnlineDescription,
    statusLabel: 'Expired',
    info: infoForExpired,
  },
  CUST_ADDR_UPDATED: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: changedAddressTitle,
    description: ifYouNeedToReplaceIfHasRecentlyUpdatedAddressDescription,
    statusLabel: '',
    info: [],
  },
  ACTIVE_PHOTOCARD_FOUND: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: notFoundTitle,
    description: activePhotoCardFoundDescription,
    statusLabel: '',
    info: [],
  },
  ADDRESS_SUPPRESSED: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: weNeedToCheckDetailsTitle,
    description: forMoreInformationDescription,
    statusLabel: '',
    info: [],
  },
  CUSTOMER_HAS_CAMS: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: weNeedToCheckDetailsTitle,
    description: forMoreInformationDescription,
    statusLabel: '',
    info: [],
  },
  CUSTOMER_HAS_UNCLASSIFIED_RESI_STATUS: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: weNeedToCheckDetailsTitle,
    description: forMoreInformationDescription,
    statusLabel: '',
    info: [],
  },
  CUSTOMER_NOT_FOUND: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: yourDetailsNotFoundTitle,
    description: callUsOrVisitWithApology,
    statusLabel: '',
    info: [],
  },
  CUSTOMER_HAS_NO_LICENCE: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: noLicenceFoundTitle,
    description: callUsOrVisitWithApology,
    statusLabel: '',
    info: [],
  },
  MPS_CARD_FOUND_NO_COND: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: weNeedToCheckDetailsTitle,
    description: mpsCardFoundDescription,
    statusLabel: '',
    info: [],
  },
  CUST_RECD_LIC_IN_14_DAYS: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: replacementLast14DaysTitle,
    description: licenceOrderedInLast14DaysDescription,
    statusLabel: '',
    info: [],
  },
  LIC_PENDING_CANC: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: licencePendingCancellationTitle,
    description: licencePendingCancelSuspension,
    statusLabel: 'cancellation',
    info: [],
  },
  LIC_PENDING_SUSPENSION: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: licencePendingSuspensionTitle,
    description: licencePendingCancelSuspension,
    statusLabel: 'suspension',
    info: [],
  },
  VALID_IMAGE_NOT_EXIST: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: 'We do not have a valid photo of you on record',
    description: visitServiceCenterForPhoto,
    statusLabel: '',
    info: [],
  },
  ADMIN_INELIGIBILITY_EXISTS: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: weNeedToCheckDetailsTitle,
    description: forMoreInformationDescription,
    statusLabel: '',
    info: [],
  },
  ACTIVE_REFUSAL_PRESENT: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: restrictedLicenceTitle,
    description: restrictedLicenceDescription,
    statusLabel: '',
    info: [],
  },
  ACTIVE_SANCTION_EXISTS: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: restrictedLicenceTitle,
    description: restrictedLicenceDescription,
    statusLabel: '',
    info: [],
  },
  SYSTEM_FAILURE_GENERIC: {
    pageHeading: sorryAboutThisPageHeading,
    title: systemUnvailableTitle,
    description: systemUnvailableDescription,
    statusLabel: '',
    info: [],
  },
  SYSTEM_FAILURE: {
    pageHeading: sorryAboutThisPageHeading,
    title: systemUnvailableTitle,
    description: systemUnvailableDescription,
    statusLabel: '',
    info: [],
  },
  SCHEMA_VALIDATION_ERROR: {
    pageHeading: sorryAboutThisPageHeading,
    title: systemUnvailableTitle,
    description: systemUnvailableDescription,
    statusLabel: '',
    info: [],
  },
  INVALID_INPUT: {
    pageHeading: sorryAboutThisPageHeading,
    title: systemUnvailableTitle,
    description: systemUnvailableDescription,
    statusLabel: '',
    info: [],
  },
  INVALID_MSG_SOLN: {
    pageHeading: sorryAboutThisPageHeading,
    title: systemUnvailableTitle,
    description: systemUnvailableDescription,
    statusLabel: '',
    info: [],
  },
  PENSIONER_CHECK_DEFERRED_ON_PENSIONER_VALIDATION: {
    pageHeading: '',
    title: consessionCanNotValidateTitle,
    description: pensionerValidationDeferredDescription,
    statusLabel: '',
    info: [],
  },
  NOT_ELIGIBLE_FOR_PENSION_CONCESSION: {
    pageHeading: '',
    title: notEligibleForPensionConssesionTitle,
    description: unableToverifyConssesionDescription,
    statusLabel: '',
    info: [],
  },
  NOT_ELIGIBLE: {
    pageHeading: sorryAboutThisPageHeading,
    title: youCannotReplaceLicencePageHeading,
    description: notEligibleDescription,
    statusLabel: '',
    info: [],
  },
  DECL_ALREADY_EXISTS: {
    pageHeading: '',
    title: declarationAlreadyExistTitle,
    description: declarationAlreadyExistDescription,
    statusLabel: '',
    info: [],
  },
  ATTEND_SERVICE_CENTRE: {
    pageHeading: finishApplicationInPersonHeading,
    title: cannotProceedOnlineTitle,
    description: cannotProceedOnlineDescription,
    statusLabel: '',
    info: infoForCannotProceedOnline,
  },
  VISIT_SERVICE_CENTRE: {
    pageHeading: sorryAboutThisPageHeading,
    title: youCannotReplaceLicencePageHeading,
    description: visitServiceCentreDescription,
    statusLabel: '',
    info: [],
  },

  APPEALED_SUSPENSION_PRESENT: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: 'Your NSW Driver Licence is suspended',
    description: singleClassExpiredCancelledDisqualifiedDesc,
    statusLabel: '',
    info: [],
  },

  APPEALED_CANC_PRESENT: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: 'Your NSW Driver Licence is cancelled',
    description: singleClassExpiredCancelledDisqualifiedDesc,
    statusLabel: '',
    info: [],
  },

  MULTIPLE_DECL_FOUND: {
    pageHeading: finishApplicationInPersonHeading,
    title: cannotProceedOnlineTitle,
    description: multipleDeclarationFoundDescription,
    statusLabel: '',
    info: [],
  },

  WARNING: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: 'Warning',
    description: null,
    statusLabel: '',
    info: [],
  },

  ERROR: {
    pageHeading: cannotReplaceLicencePageHeading,
    title: 'An Error Occurred.',
    description: forMoreInformationDescription,
    statusLabel: '',
    info: [],
  },
};

export const LicenceStatusDetailsDualClass: DualClassErrorMessageType = {
  LIC_STATUS_DISQUALIFIED: {
    title: dualClassTitleOneDisqualified,
    description: dualClassDescriptionOneDisqualified,
  },
  LIC_STATUS_CANCELLED: {
    title: dualClassTitleOneCancelled,
    description: dualClassDescriptionOneCancelled,
  },
  LIC_STATUS_SUSPENDED: {
    title: dualClassTitleOneSuspended,
    description: dualClassDescriptionOneSuspended,
  },
  LIC_EXPIRED: {
    title: dualClassTitleOneExpired,
    description: dualClassDescriptionOneExpired,
  },
  APPEALED_SUSPENSION_PRESENT: {
    title: dualClassTitleOneSuspended,
    description:
      dualClassHasActiveAndAppealedSanctionOrCancelPresentDescription(
        'suspension'
      ),
  },
  APPEALED_CANC_PRESENT: {
    title: dualClassTitleOneCancelled,
    description:
      dualClassHasActiveAndAppealedSanctionOrCancelPresentDescription(
        'cancellation'
      ),
  },
};

export const LicenceStatusPendingDualClass = {
  LIC_PENDING_SUSPENSION: {
    title: dualClassTitlePendingCancSuspension('suspension'),
    description: licencePendingCancOrSuspendedDualClass,
  },

  LIC_PENDING_CANC: {
    title: dualClassTitlePendingCancSuspension('cancellation'),
    description: licencePendingCancOrSuspendedDualClass,
  },
};
