import styled from 'styled-components';
import { Breakpoint } from '@rmstransactions/components/Styled';
import { tokens } from '@snsw/react-component-library';
import { InPageAlert, TextLink } from '@snsw/react-component-library';

export const HeaderContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PrintOrDowloadCardContainer = styled.div`
  border-radius: 6px;
  border: 2px solid ${tokens.colors.grey.geyser};
  padding: 1.25rem;
  ${Breakpoint('sm')`
   padding: 2rem 2.5rem 2.5rem 2.5rem;
  `}
`;

export const InlineWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3.5rem
  ${Breakpoint('sm')`
    flex-wrap: unset;
    `}
  .formGroup {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0.5rem;
    flex: 0 1 27.3rem;
    ${Breakpoint('sm')`
      margin-right: 1.5rem;
      margin-bottom: 0;
    `}
  .input {
    margin-top: 0;
  }
`;

export const FormEmailWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-flow: column;

  ${Breakpoint('sm')`
    flex-flow: row;
  `}

  label {
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  }

  .field-item {
    max-width: 430px;
    margin: 0.25rem 0;
  }

  button {
    min-width: 112px;
    height: 3rem;
    margin: 0.25rem 0 0 0;

    ${Breakpoint('sm')`
      margin: 0.5rem 0 0 0.5rem;
    `}
  }
`;

export const ButtonLinkStyle = styled.span`
  .buttonLink {
    font-weight: 300;
    :hover {
      background-color: unset;
      color: ${tokens.colors.brand.snswSecondaryBlue};
    }
  }
`;

export const InPageAlertHeaderOverride = styled(InPageAlert)`
  div {
    flex-direction: column;
    gap: 0.5rem;
    ${Breakpoint('sm')`
    flex-direction: row;
    gap: 0
  `}
  }
`;

export const TextLinkBlue = styled(TextLink)`
  color: ${tokens.colors.brand.snswSecondaryBlue} !important;
`;

export const InWarningUnorderedWrapper = styled.ul`
  padding-left: 0.5rem;
  padding-top: 0;
  padding-inline-start: 1rem;
  ${Breakpoint('sm')`
    padding-inline-start: 1.5rem;
  `};
`;
export const InWarningList = styled.li`
  margin-bottom: 0 !important;
`;

export const MediumFont = styled.span`
  font-weight: 500;
`;

export const ListWrapper = styled.ul`
  padding-left: 1rem;
  ${Breakpoint('sm')`
  padding-left: 1rem;
`};
`;

export const PrintOrDownloadWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  ${Breakpoint('sm')`
  justify-content: left;
  gap: unset;
  flex-wrap: unset;
  `};
`;

interface FlexWithGapProps {
  gap?: string;
}

export const FlexWithGapWrapper = styled.div<FlexWithGapProps>`
  display: flex;
  flex-direction: Column;
  gap: ${(props) => (props.gap ? props.gap : '1rem')};
  ${Breakpoint('sm')`
    flex-direction: row;
  `};
`;
