import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICustomerDetails {
  customerId: string | null;
  status: string | null;
  unstructuredName: string | null;
  givenName: string | null;
  familyName: string | null;
}

export const initialState: ICustomerDetails = {
  customerId: null,
  status: null,
  unstructuredName: null,
  givenName: null,
  familyName: null,
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerDetails: (
      state,
      action: PayloadAction<{
        customerId: string;
        unstructuredName: string;
        givenName: string;
        familyName: string;
      }>
    ) => {
      const { customerId, unstructuredName, givenName, familyName } =
        action.payload;
      state.customerId = customerId;
      state.unstructuredName = unstructuredName;
      state.givenName = givenName;
      state.familyName = familyName;
      state.status = 'success';
    },
    resetCustomerDetails: () => initialState,
  },
});

export const customerSliceActions = customerSlice.actions;
export const customerReducer = customerSlice.reducer;
