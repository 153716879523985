import React from 'react';
import { SkeletonLoader, Spacer } from '@rmstransactions/components';

const Loader: React.FC<{ width?: string }> = ({ width = '100%' }) => {
  return (
    <Spacer mb='0.25rem'>
      <SkeletonLoader
        id='customerDetailsLoader1'
        title='Loading'
        height='25'
        width={width}
      />
    </Spacer>
  );
};

export default Loader;
